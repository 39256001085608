import axiosInstance from "../axios.config";

export default async function DeleteAdmin(id: string) {
  try {
    const { data } = await axiosInstance.delete(`/admin/auth/delete_admin/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}
