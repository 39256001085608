import React from "react";
import { AdminTableProps } from "../../../interfaces/GetAdminsData.interface";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { RiDeleteBin2Line } from "react-icons/ri";
const AdminTable: React.FC<AdminTableProps> = ({ admins, onDelete }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full  border border-gray-300">
        <thead>
          <tr className="w-full bg-gray-500">
            <th className="py-2 px-4 border-b">{lang.name}</th>
            <th className="py-2 px-4 border-b">{lang.email}</th>
            <th className="py-2 px-4 border-b">{lang.phone}</th>
            <th className="py-2 px-4 border-b">{lang.admin_level}</th>
            <th className="py-2 px-4 border-b">{lang.created_at}</th>
            <th className="py-2 px-4 border-b">{lang.actions}</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr className="border-b   border-gray-200 hover:bg-gray-400">
              <td className="py-2 px-4 text-center">{admin.name}</td>
              <td className="py-2 px-4 text-center">{admin.email}</td>
              <td className="py-2 px-4 text-center">{admin.phone}</td>
              <td className="py-2 px-4 text-center">{admin.level}</td>
              <td className="py-2 px-4 text-center">
                {new Date(admin.createdAt).toLocaleDateString()}
              </td>
              <td className="py-2 px-4 text-center">
                <button onClick={() => onDelete(admin._id)}>
                  <RiDeleteBin2Line className="text-red-500 hover:text-red-600 h-6 w-6" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
