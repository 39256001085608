import React from "react";
import { EMAIL } from "../../utils/constants";
import { MdEmail } from "react-icons/md";

interface Props {
  email: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean | false;
  placeholder?: string;
}

export default function Email({ email, onChange, disabled, placeholder }: Props) {
  return (
    <div className="relative">
      <input
        id={EMAIL}
        type="email"
        className="border border-gray-300 w-[100%] shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder={placeholder ?? "Email *"}
        onChange={onChange}
        value={email}
        disabled={disabled}
      />
      <MdEmail className="input-icon" />
    </div>
  );
}
