import React from "react";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { FILED, IN_PROGRESS, NOT_STARTED } from "../../../utils/constants";
import Loading from "../../Decorations/Loading";
import User from "../../../interfaces/AdminPanelUserDetails.interface";

interface UsersContentProps {
  users: User[];
  loading: boolean;
  error: string | null;
  onRowClick: (user: User) => void;
}
function ListTable({ users, loading, error, onRowClick }: UsersContentProps) {
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  //   if (error) return <div>{error}</div>;

  return (
    <div className="">
      {/* <h2 className="text-xl font-bold mb-4">Users List</h2> */}
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full  border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">{lang.name}</th>
              <th className="py-2 px-4 border-b">{lang.email}</th>
              <th className="py-2 px-4 border-b">{lang.phone}</th>
              <th className="py-2 px-4 border-b">{lang.verified}</th>
              <th className="py-2 px-4 border-b">{lang.created_at}</th>
              <th className="py-2 px-4 border-b">{lang.tax_year}</th>
              <th className="py-2 px-4 border-b">{lang.tax_details}</th>
              <th className="py-2 px-4 border-b">{lang.paid}</th>
              <th className="py-2 px-4 border-b">{lang.filing_status}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr onClick={() => onRowClick(user)} className="text-center border-t">
                <td className="py-2 px-4 border-b">{user.name}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">{user.phone}</td>
                <td
                  className={`py-2 px-4 border-b ${
                    user.is_verified ? "bg-green-500" : "bg-red-500"
                  }`}
                >
                  {user.is_verified ? "Yes" : "No"}
                </td>
                <td className="py-2 px-4 border-b">
                  {new Date(user.createdAt).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b">{user?.status?.taxyear}</td>
                <td
                  className={`py-2 px-4 border-b ${
                    user.status?.tax_details_submitted === null
                      ? ""
                      : user.status?.tax_details_submitted
                      ? "bg-green-500"
                      : "bg-red-500"
                  } border-r-2 border-gray-200`}
                >
                  {user.status?.tax_details_submitted === null
                    ? null
                    : user.status?.tax_details_submitted
                    ? "Yes"
                    : "No"}
                </td>
                <td
                  className={`py-2 px-4 border-b ${
                    user.status?.paid === null
                      ? ""
                      : user.status?.paid
                      ? "bg-green-500"
                      : "bg-red-500"
                  } border-r-2 border-gray-200`}
                >
                  {user.status?.paid === null ? null : user.status?.paid ? "Yes" : "No"}
                </td>
                <td
                  className={`py-2 px-4 border-b ${
                    user.status?.filing_status === NOT_STARTED
                      ? "bg-red-500"
                      : user.status?.filing_status === IN_PROGRESS
                      ? "bg-yellow-500"
                      : user.status?.filing_status === FILED
                      ? "bg-green-500"
                      : ""
                  }`}
                >
                  {user.status?.filing_status === NOT_STARTED
                    ? "Not Started"
                    : user.status?.filing_status === IN_PROGRESS
                    ? "In-Progress"
                    : user.status?.filing_status === FILED
                    ? "Filed"
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListTable;
