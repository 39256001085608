import lang from "../utils/LanguagePacks/en-us.json";
interface FormData {
  firstName: string;
  middleName?: string;
  lastName: string;
  zip: string;
  city: string;
  occupation: string;
  addressLine1: string;
  addressLine2?: string;
  dob: string;
  tin: string;
  taxResidency: string;
  additionalInfo?: string;
  foreignState?: string;
  digitalAssets?: string;
  state: {
    state_name: string;
    state_id: string;
  };
  country: {
    country_name: string;
    country_id: string;
  };
  [key: string]: any;
}



export const validateForm = (
  formData: FormData,
  isForeignAddress: boolean
): { [key: string]: string } => {
  
  const errors: { [key: string]: string } = {};

  if (!formData.firstName) {
    errors.firstName = lang.first_name_required;
  }

  if (!formData.lastName) {
    errors.lastName = lang.last_name_required;
  }

  if (!formData.zip) {
    errors.zip = lang.zip_required;
  }

  if (!formData.city) {
    errors.city = lang.city_required;
  }

  if (!formData.occupation) {
    errors.occupation = lang.occupation_required;
  }

  if (!formData.addressLine1) {
    errors.addressLine1 = lang.address_required;
  }

  if (!formData.dob) {
    errors.dob = lang.dob_required;
  }

  if (formData.tintype !== lang.nota && !formData.tin) {
    errors.tin = lang.tin_required;
  }
  if (formData.tintype === lang.nota && !formData.itinApplication) {
    errors.itinApplication = lang.additional_question_required;
  }
  if (!formData.tintype) {
    errors.tintype = lang.residential_non_residential_required;
  }

  if (!formData.taxResidency) {
    errors.taxResidency = lang.residential_non_residential_required;
  }

  if (!isForeignAddress && !formData.state.state_name) {
    errors.state = lang.state_required;
  }
 

  if (isForeignAddress && !formData.country.country_name) {
    //this validation is show only when foreign address is true
    errors.country = lang.country_required;
  }
  if (!formData.digitalAssets) {
    errors.digitalAssets = lang.additional_question_required;
  }
   if (formData.taxResidency === lang.residential && !formData.foreign_country_account) {
     errors.foreign_country_account = lang.additional_question_required;
   }
  // if (!formData.additional) {
  //    errors.additional = "Additional information is required";
  // }

  return errors;
};
