import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IconType } from "react-icons";

interface CustomDatePickerProps {
  value: string; // Date in mm-dd-yyyy format
  onChange: (date: string) => void; // Function to update the date in parent
  startYear: number; // Start year for selection
  endYear: number; // Last year for selection
  label: string;
  placeholder: string;
  required: boolean;
  Icon: IconType;
  disabled?: boolean;
}

function CustomDatePicker({
  value,
  onChange,
  startYear,
  endYear,
  label,
  placeholder,
  required,
  Icon,
  disabled = false,
}: CustomDatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date(); // Current date
  const [selectedDate, setSelectedDate] = useState<string | null>(value || null); // Date from props or null

  const [selectedMonth, setSelectedMonth] = useState<number>(
    value ? parseInt(value.split("-")[0]) - 1 : currentDate.getMonth()
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    value ? parseInt(value.split("-")[2]) : currentDate.getFullYear()
  );

  const [showMonthSelect, setShowMonthSelect] = useState(false);
  const [showYearSelect, setShowYearSelect] = useState(false);

  const datePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Open modal logic
  const handleOpenCalendar = () => {
    if (!isOpen) {
      setIsOpen(true);

      // If no value is provided, default to highlighting the current date
      if (!value) {
        setSelectedMonth(currentDate.getMonth());
        setSelectedYear(currentDate.getFullYear());
      }
    }
  };

  const handleDateSelect = (day: number) => {
    const newDate = `${(selectedMonth + 1).toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${selectedYear}`;
    setSelectedDate(newDate);
    onChange(newDate);
    setIsOpen(false);
  };

  const handleMonthChange = (delta: number) => {
    let newMonth = selectedMonth + delta;
    let newYear = selectedYear;

    if (newMonth < 0) {
      if (newYear > startYear) {
        newMonth = 11;
        newYear -= 1;
      } else {
        return;
      }
    } else if (newMonth > 11) {
      if (newYear < endYear) {
        newMonth = 0;
        newYear += 1;
      } else {
        return;
      }
    }

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const handleYearChange = (delta: number) => {
    const newYear = selectedYear + delta;

    if (newYear < startYear || newYear > endYear) {
      return;
    }

    setSelectedYear(newYear);
  };

  const handleMonthSelect = (month: number) => {
    setSelectedMonth(month);
    setShowMonthSelect(false);
  };

  const handleYearSelect = (year: number) => {
    setSelectedYear(year);
    setShowYearSelect(false);
  };

  const handleMonthYearClick = (type: "month" | "year") => {
    if (type === "month") {
      setShowMonthSelect(true);
      setShowYearSelect(false);
    } else {
      setShowYearSelect(true);
      setShowMonthSelect(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key
    }
  };

  return (
    <div ref={datePickerRef} className={`${!required && "md:mt-1"}`}>
      <div className={`${!required && "md:mb-2"} flex flex-row items-center gap-1`}>
        <label className="block text-sm font-medium">{label}</label>
        {required && <span className="text-red-700 text-2xl">*</span>}
      </div>
      <div
        className={`relative mt-1 flex items-center border border-gray-300 rounded-[9px] shadow-sm focus-within:ring focus-within:ring-blue-300 ${
          disabled && "bg-slate-200"
        }`}
      >
        <span className="absolute inset-y-6 flex items-center pl-3 text-gray-500">
          <Icon className="h-6 w-6" />
        </span>
        <div>
          <input
            type="text"
            value={selectedDate || ""}
            onClick={handleOpenCalendar} // Only opens the modal
            onFocus={handleOpenCalendar} // Open on Tab focus
            placeholder={placeholder}
            readOnly
            className="shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] block w-64 min-w-full pl-10 pr-3 outline-none ring-blue-300 focus:ring-1 border-none cursor-pointer"
            onKeyDown={handleKeyDown} // Prevent form submit on Enter key
            disabled={disabled}
          />
          {isOpen && (
            <div className="absolute md:w-96 left-0 right-0 z-10 bg-white dark:bg-gray-600 shadow-lg p-4 flex flex-col justify-between rounded-md">
              {/* Month and Year header with navigation */}
              <div className="flex justify-between items-center mb-4">
                <FaChevronLeft
                  className="cursor-pointer text-xl"
                  onClick={() => handleYearChange(-1)}
                />
                <FaChevronLeft
                  className="cursor-pointer text-xl"
                  onClick={() => handleMonthChange(-1)}
                />
                <div className="flex space-x-4">
                  <span className="cursor-pointer" onClick={() => handleMonthYearClick("month")}>
                    {new Date(0, selectedMonth).toLocaleString("en-US", { month: "long" })}
                  </span>
                  <span className="cursor-pointer" onClick={() => handleMonthYearClick("year")}>
                    {selectedYear}
                  </span>
                </div>
                <FaChevronRight
                  className="cursor-pointer text-xl"
                  onClick={() => handleMonthChange(1)}
                />
                <FaChevronRight
                  className="cursor-pointer text-xl"
                  onClick={() => handleYearChange(1)}
                />
              </div>

              {/* Month selection dropdown */}
              {showMonthSelect && (
                <div className="grid grid-cols-3 gap-2 mb-4">
                  {Array(12)
                    .fill(0)
                    .map((_, i) => (
                      <button
                        onClick={() => handleMonthSelect(i)}
                        className={`p-2 border hover:bg-gray-200 rounded-md text-center ${
                          i === selectedMonth ? "bg-blue-200" : ""
                        }`}
                      >
                        {new Date(0, i).toLocaleString("en-US", { month: "long" })}
                      </button>
                    ))}
                </div>
              )}

              {/* Year selection dropdown */}
              {showYearSelect && (
                <div className="grid grid-cols-4 gap-2 mb-4 overflow-y-auto h-52">
                  {Array(endYear - startYear + 1)
                    .fill(0)
                    .map((_, i) => (
                      <button
                        onClick={() => handleYearSelect(startYear + i)}
                        className={`p-2 border hover:bg-gray-200 rounded-md text-center ${
                          startYear + i === selectedYear ? "bg-blue-200" : ""
                        }`}
                      >
                        {startYear + i}
                      </button>
                    ))}
                </div>
              )}

              {/* Days in the selected month */}
              {!showMonthSelect && !showYearSelect && (
                <div className="grid grid-cols-7 gap-2">
                  {Array(new Date(selectedYear, selectedMonth + 1, 0).getDate())
                    .fill(0)
                    .map((_, i) => (
                      <button
                        onClick={() => handleDateSelect(i + 1)}
                        className={`p-2 hover:bg-blue-200 rounded-md ${
                          !value &&
                          selectedMonth === currentDate.getMonth() &&
                          selectedYear === currentDate.getFullYear() &&
                          currentDate.getDate() === i + 1
                            ? "bg-blue-300" // Highlight current date if no value is selected
                            : selectedDate ===
                              `${(selectedMonth + 1).toString().padStart(2, "0")}-${(i + 1)
                                .toString()
                                .padStart(2, "0")}-${selectedYear}`
                            ? "bg-blue-300"
                            : ""
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomDatePicker;
