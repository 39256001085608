import React from "react";
import ProfileSettingsButton from "./ProfileSettingsButton";
import Logout from "./Logout";

interface Props {
  username: string;
  email: string;
}

export default function ProfileInfoPopup({ username, email }: Props) {
  return (
    <div className="z-50 absolute -bottom-[15rem] -left-64 w-[23rem] shadow-xl rounded-2xl overflow-hidden pi-popup">
      <div className="py-4 px-6">
        <p className="font-bold">{username}</p>
        <p className="opacity-60">
          <em>{email}</em>
        </p>
      </div>
      <ProfileSettingsButton />
      <Logout />
    </div>
  );
}
