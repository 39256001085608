import AdminManagementData from "../interfaces/AdminManagementData.interface";

export const validateCreateAdminForm = (data: AdminManagementData) => {
  const errors: { [key: string]: string } = {};

  // Trim whitespace and check for empty fields
  const email = data.email.trim();
  const password = data.password.trim();
  const name = data.name.trim();
  const phone = data.phone.trim();
  const level = data.level;

  // Validate email
  if (!email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "Email is invalid";
  }

  // Validate password
  if (!password) {
    errors.password = "Password is required";
  } else if (password.length < 8) {
    errors.password = "Password must be at least 8 characters long";
  }

  // Validate name
  if (!name) {
    errors.name = "Name is required";
  }

  // Validate phone number
  if (!phone) {
    errors.phone = "Phone number is required";
  } else if (!/^\d{10}$/.test(phone)) {
    errors.phone = "Phone number must be 10 digits";
  }

  // Validate level
  if (!level.level_id || !level.level_name) {
    errors.level = "Level is required";
  }

  return errors;
};
