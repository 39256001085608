// src/ga.ts
import ReactGA from "react-ga4";

// Initialize Google Analytics with the Measurement ID (replace with your actual ID)
export const initGA = (measurementId: string) => {
  ReactGA.initialize(measurementId); // Initialize Google Analytics with your measurement ID
};

// Log page views with custom page name
export const logPageView = (page: string, title: string) => {
  ReactGA.send({ hitType: "pageview", page: page, title: title });
};

// Log events
export const logEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category: category,
    action: action,
    ...(label && { label }),
  });
};
