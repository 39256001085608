import React, { useEffect, useState } from "react";
import ListTable from "../AdminComponent/ListTable";
import AdminHandler from "../../../classes/AdminHandler";
import lang from "../../../utils/LanguagePacks/en-us.json";
import YearDropdown from "../../YearDropdown";
import Loading from "../../Decorations/Loading";
import { devLogger } from "../../../utils/logger.config";
import UserInspect from "./UserInspect";
import User from "../../../interfaces/AdminPanelUserDetails.interface";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS

function UserContent() {
  const [showTable, setShowTable] = useState(false); // State to toggle table visibility
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // Track selected user
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear - 1);

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
  };

  const fetchUserData = async (year: number) => {
    setLoading(true);
    try {
      const res = await AdminHandler.handleUserData(year);
      const updatedUsers = res?.combinedArray.map((user: { status: any; _id: any }) => ({
        ...user,
        status: user.status || {
          user_id: user._id,
          taxyear: null,
          tax_details_submitted: null,
          paid: null,
          filing_status: null,
          createdAt: null,
        },
      }));

      setUsers(updatedUsers);
    } catch (error) {
      devLogger.error(error);
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleShowTable = () => {
    setShowTable(true);
    fetchUserData(selectedYear);
  };

  const handleRowClick = (user: User) => {
    if (user.status?.tax_details_submitted) {
      setSelectedUser(user);
    } else {
      toast.error(lang.tax_not_submitted);
    }
    // Set the selected user when a row is clicked
  };

  const handleBackToTable = () => {
    setSelectedUser(null); // Go back to table view by resetting the selected user
  };

  useEffect(() => {
    if (showTable) {
      fetchUserData(selectedYear);
    }
  }, [selectedYear]);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">{selectedUser ? lang.user_detail : lang.user_list}</h2>
      <ToastContainer />
      {!showTable ? (
        <button
          onClick={handleShowTable}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {lang.all_user}
        </button>
      ) : selectedUser ? (
        // Display user details when a row is clicked

        <>
          <div className="w-56"></div>

          <button
            onClick={handleBackToTable}
            className="bg-gray-500 text-white px-4 py-2 rounded mb-4 hover:bg-gray-600"
          >
            back
          </button>
          <UserInspect user={selectedUser} year={selectedYear} />
        </>
      ) : (
        <>
          <YearDropdown currentYear={currentYear} onYearChange={handleYearChange} />
          <ListTable users={users} loading={loading} error={error} onRowClick={handleRowClick} />
        </>
      )}
    </div>
  );
}

export default UserContent;
