import { ToastMessageDispatch } from "../../interfaces/ToastMessageDispatch.interface";
import ToastMessageObj from "../../interfaces/ToastMessageObj.interface";
import { SET_TOAST_MESSAGE } from "../actions/action.types";

const initial: ToastMessageObj = {
  message: "",
  type: "",
  toDisplay: false,
};

const toastMessage = (state = initial, action: ToastMessageDispatch) => {
  const { type, payload } = action;
  if (type === SET_TOAST_MESSAGE) return payload;
  return state;
};

export default toastMessage;
