import axios from "axios";
import Cookie from "../classes/Cookie";
import { SS } from "./constants";
import Cache from "../classes/Cache";
import Token from "../classes/Token";
import User from "../classes/User";
import store from "../redux/store";
import { devLogger } from "./logger.config";
import AdminAuth from "../classes/AdminAuth";

// Creating the axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:5050",
  withCredentials: true, // Allow credentials (cookies)
  timeout: 60000, // 60 seconds timeout
});

// Interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response, // If the response is successful, return it

  async (error) => {
    const originalRequest = error.config;
    const isAdminApi = originalRequest.url.includes("admin");
    // If 401 error (token expired or invalid)
    if (error.response?.status === 401) {
      // Retrieve existing tokens from storage (cookie and cache)
      const existingTokens = {
        access_token: Cookie.retrieveCookie("auth_token") ?? "",
        refresh_token: new Cache(SS).get("refresh_token") ?? "",
      };
      const adminExistingTokens = {
        access_token: Cookie.retrieveCookie("auth_token") ?? "",
        refresh_token: localStorage.getItem("admin_refresh_token") ?? "",
      };
      if (!isAdminApi) {
        // Check if the tokens exist before making the refresh request
        if (!existingTokens.access_token || !existingTokens.refresh_token) {
          // Optionally, redirect to login or perform logout
          return Promise.reject(error);
        }
      }

      try {
        if (isAdminApi) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/admin/token_refresh/${adminExistingTokens.refresh_token}`
          );
          Cookie.setCookie("admin_access_token", response.data.adminAccessToken); // Store the access token in cookie
          localStorage.setItem("admin_refresh_token", response.data.adminRefreshToken);
        } else {
          // Call the refresh token API to get new access and refresh tokens
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/refresh_tokens`,
            {
              refresh_token: existingTokens.refresh_token,
            }
          );
          // Save new tokens in the appropriate places (cookies, cache, or redux store)
          Token.saveAccessToken("auth_token", response.data.access_token);
          Token.saveRefreshToken("refresh_token", response.data.refresh_token);
        }

        // Retry the request with the new access token and return the response
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Perform necessary actions like logging out or redirecting to the login page
        if (isAdminApi) {
          const adminAuth = new AdminAuth(); // Create an instance of AdminAuth
          adminAuth.logoutAdmin();
        } else {
          User.logout(store.dispatch);
        }

        devLogger.error(refreshError);
        return Promise.reject(refreshError);
      }
    }

    // If not 401 error, reject the promise with the original error
    return Promise.reject(error);
  }
);

export default axiosInstance;
