import React, { useState } from "react";
import { LuLogOut } from "react-icons/lu";
import User from "../../classes/User";
import { useDispatch } from "react-redux";

export default function Logout() {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  // Handle logout
  const handleLogout = () => {
    User.logout(dispatch);
  };

  return (
    <div
      className="relative px-6 py-4 transition flex items-center text-xl cursor-pointer"
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={handleLogout}
    >
      <span className={`${isHovered && "text-[#CDF5FD]"}`}>Logout</span>{" "}
      <LuLogOut
        className={`text-2xl transition-transform duration-700 absolute right-4 z-[2] ${
          isHovered && "-translate-x-[23rem]"
        }`}
      />
      <div
        className={`absolute transition-[width] duration-500 left-0 bg-red-500 h-[100%] rounded-r-lg ${
          isHovered ? "w-[100%]" : "w-0"
        }`}
      />
    </div>
  );
}
