import React from "react";
import MainImg from "../../components/MainImg";
import LoginForm from "../../components/AuthRelated/LoginForm";
import { useLogin } from "../../utils/customHooks";
import AdminLoginHandler from "../../utils/api/AdminLoginHandler";

const loginHelper = (email: string, password: string) => {
  new AdminLoginHandler(email, password).login();
};

export default function AdminLogin() {
  const { loginData, handleChange, handleLogin, showErrorIndicator } = useLogin(loginHelper);

  return (
    <div className="page justify-center">
      <section className="flex gap-10 items-center">
        <MainImg />
        <LoginForm
          email={loginData.email}
          password={loginData.password}
          onChange={handleChange}
          onSubmit={handleLogin}
          showIndicator={showErrorIndicator}
          onAdminPortal
        />
      </section>
    </div>
  );
}
