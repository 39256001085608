const formatFileName = (fileName: string): string => {
  // Regex to identify the first sequence of digits followed by an underscore
  const timestampRegex = /^\d+_/;

  // Remove the timestamp and the underscore immediately following it
  const cleanedName = fileName.replace(timestampRegex, "");

  // Return the cleaned name as-is without replacing other underscores
  return cleanedName;
};

export default formatFileName;
