import React from "react";

const Loading = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <div className="relative flex items-center justify-center">
        {/* Outer spinning circle */}
        <div className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 border-4 md:border-8 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default Loading;
