import React from "react";
import { PASSWORD } from "../../utils/constants";
import lang from "../../utils/LanguagePacks/en-us.json";
import { UserData } from "../../classes/User";
import PasswordSetupInstruction from "./PasswordSetupInstruction";
import { ShowIndicatorOn } from "../../pages/Register";
import Indicator from "../Indicator";
import Password from "./Password";
import { Link } from "react-router-dom";
import { LOGIN } from "../../utils/routes";
import Phone from "./Phone";
import Name from "./Name";
import Email from "./Email";
import { BiUser } from "react-icons/bi";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  userdata: UserData;
  showPassInstruction: boolean;
  passConfrimVal: string;
  showIndicator?: ShowIndicatorOn;
}

export default function RegistrationForm({
  onChange,
  onSubmit,
  userdata,
  showPassInstruction,
  passConfrimVal,
  showIndicator,
}: Props) {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen relative mt-7">
        <div className="w-full max-w-md  shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] rounded-2xl p-6 sm:p-8 border border-gray-200 transform hover:scale-105 transition duration-300 ">
          <form className="space-y-4 mt-16" onSubmit={onSubmit}>
            <div>
              <Name onChange={onChange} name={userdata.name} />
              {showIndicator?.onName && <Indicator message={lang.required} />}
            </div>
            <div>
              <Email onChange={onChange} email={userdata.email} />
              {showIndicator?.onEmail && <Indicator message={lang.email_required} />}
            </div>
            <div>
              <Phone onChange={onChange} phone={userdata.phone} />
              {showIndicator?.onPhone && <Indicator message={lang.required} />}
            </div>
            <div className="relative">
              <Password
                id={PASSWORD}
                onChange={onChange}
                value={userdata.password}
                placeholder="Password *"
              />
              {showPassInstruction && <PasswordSetupInstruction />}
              {showIndicator?.onPass && <Indicator message={lang.pass_required} />}
            </div>
            <div>
              <Password
                id={`CONFIRM_${PASSWORD}`}
                onChange={onChange}
                value={passConfrimVal}
                placeholder="Confirm Password *"
              />
              {showIndicator?.onConfirmPass && <Indicator message={lang.pass_dont_match} />}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="w-full py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Sign Up
              </button>
            </div>
          </form>
          <div className="py-6 text-center text-light-black font-semibold font-sans">
            {lang.already_have_an_account}{" "}
            <Link to={LOGIN} className="link text-student-tax-secondary-theme-color font-semibold">
              Sign in
            </Link>
          </div>
        </div>
        <div className="h-32 w-32 md:h-40 md:w-40 bg-navy-blue rounded-full absolute top-0 flex justify-center items-center">
          <BiUser className="text-white-color text-8xl" />
        </div>
      </div>
    </>
  );
}
