import ContactUsData from "../../interfaces/ContactUsData.interface";
import axiosInstance from "../axios.config";
import { prodLogger } from "../logger.config";

export default async function contactUs(data_: ContactUsData) {
  try {
    const { data } = await axiosInstance.post("/contact_us", data_);
    return data;
  } catch (err) {
    prodLogger.error(err);
    throw err;
  }
}
