import React from "react";
import lang from "../../utils/LanguagePacks/en-us.json";
import EasyStepCard from "./EasyStepCard";

const EasySteps = () => {
  return (
    <div className="relative py-20">
      <div className="flex flex-col items-center px-52 mt-12">
        <div className="flex items-center flex-col mb-16 gap-2">
          <h2 className="text-5xl font-semibold text-student-tax-primary-theme-color font-mono ">
            {lang.three_easy_step}
          </h2>
          <p className="text-text-gray text-xl font-sans">{lang.three_easy_step_desc}</p>
        </div>
        <img src="/Assets/right_side_image.svg" className="absolute top-0 h-80 self-end" alt="" />
        <div className="flex flex-row justify-between w-full">
          <EasyStepCard value={lang.signup} image="/Assets/Signup Elipse.svg" />
          <EasyStepCard value={lang.fill_the_form} image="/Assets/Fill the form.svg" />

          <EasyStepCard value={lang.checkout} image="/Assets/Checkout.svg" />
        </div>
      </div>
    </div>
  );
};

export default EasySteps;
