import { devLogger } from "../utils/logger.config";
import Cookie from "./Cookie";

export default class AdminAuth {
  private static authenticateAdmin: React.Dispatch<React.SetStateAction<boolean>>;

  constructor() {
    if (!AdminAuth.authenticateAdmin)
      throw new Error("Authenticate admin prop in AdminAuth class is null!");
  }

  public verify() {
    // Check for admin access token in the cookie
    const adminAccessToken = Cookie.retrieveCookie("admin_access_token");
    devLogger.log({ adminAccessToken });
    if (!adminAccessToken) return this.logoutAdmin();

    return this.loginAdmin();
  }

  public loginAdmin() {
    // Authenticate admin
    AdminAuth.authenticateAdmin(true);
    devLogger.log("Admin authenticated!");
  }

  public logoutAdmin() {
    devLogger.log("Admin is not verified! Logging out...");
    AdminAuth.authenticateAdmin(false);

    // Delete the admin access token from cookie
    Cookie.removeCookie("admin_access_token");
    // Delete the admin refresh token from local storage (cache)
    localStorage.removeItem("admin_refresh_token");
    localStorage.removeItem("admin_level");
  }

  public static setAuthenticateAdmin(
    authenticateAdmin: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    this.authenticateAdmin = authenticateAdmin;
  }
}
