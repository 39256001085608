const routePageTitleMap: { [key: string]: string } = {
  "/home": "Home - Student Tax",
  "/history": "History - Student Tax",
  "/cart": "Cart - Student Tax",
  "/profile/settings": "Settings - Student Tax",
  "/login": "Login - Student Tax",
  "/register": "Registration - Student Tax",
  "/forgot-password": "Forget Password - Student Tax",
};
export default routePageTitleMap;
