import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LOGIN } from "../../utils/routes";
import Handler from "../../classes/Handler";
import Loading from "../../components/Decorations/Loading";
import { devLogger } from "../../utils/logger.config";
function Activation() {
  const { token } = useParams<{ token?: string }>();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [status, setstatus] = useState();

  useEffect(() => {
    const activateUser = async () => {
      if (!token) {
        setMessage("No activation token provided.");
        setError(true);
        setLoading(false);
        return; // Early exit if token is not present
      }

      setLoading(true);
      try {
        const result = await Handler.handleActivationEmail(token);

        //    if (result.success) {
        //      setMessage(result.message);
        //      setError(false);
        //    } else {
        //      setMessage(result.message);
        //      setError(true);
        //    }
        setstatus(result.status);
       
     
      } catch (error) {
        devLogger.error( error);
        setMessage("An error occurred during activation.");
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    activateUser();
  }, [token]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="">
      <section className="verified-container h-[100vh] flex flex-col items-center gap-11 py-20">
        <div className="verified-logo flex justify-center w-[15%] pl-6">
          <img src="/Assets/check-mark.svg" alt="verified" className="w-[100%]" />
        </div>
        <div className="verified-text">
          <h1 className="text-2xl">
            Email {status === 201 ? "already" : "successfully"} verified!
          </h1>
        </div>
        <div className="redirect-wrapper text-lg">
          <p>
            Go to the{" "}
            <span className="login">
              <Link to={LOGIN} className="text-taxeve-primary-blue font-bold">
                Login
              </Link>
            </span>{" "}
            page
          </p>
        </div>
      </section>
    </div>
  );
}

export default Activation;
