import React, { useEffect, useState } from "react";
import lang from "../../utils/LanguagePacks/en-us.json";
interface LeftMenuProps {
  setActiveComponent: (component: string) => void;
}
function AdminLeftMenu({ setActiveComponent }: LeftMenuProps) {
  const [selected, setSelected] = useState<string>("dashboard");

  const handleClick = (component: string) => {
    setSelected(component); // Set the selected option
    setActiveComponent(component);
  };
  const [level, setLevel] = useState<string | null>(null);
  useEffect(() => {
    // Get the level from localStorage when the component mounts
    const storedLevel = localStorage.getItem("admin_level");
    setLevel(storedLevel);
  }, []);
  return (
    <div className="w-1/5 h-screen bg-gray-900 text-white overflow-y-auto">
      <ul className="mt-4 gap-3 flex flex-col">
        <li
          className={`p-4 cursor-pointer ${
            selected === "dashboard" ? "bg-gray-700" : "hover:bg-gray-500"
          }`}
          onClick={() => handleClick("dashboard")}
        >
          {lang.dashboard}
        </li>
        <li
          className={`p-4 cursor-pointer ${
            selected === "users" ? "bg-gray-700" : "hover:bg-gray-500"
          }`}
          onClick={() => handleClick("users")}
        >
          {lang.user}
        </li>
        <li
          className={`p-4 cursor-pointer ${
            selected === "settings" ? "bg-gray-700" : "hover:bg-gray-500"
          }`}
          onClick={() => handleClick("settings")}
        >
          {lang.setting}
        </li>
        {level !== "regular" && (
          <li
            className={`p-4 cursor-pointer ${
              selected === "admin_management" ? "bg-gray-700" : "hover:bg-gray-500"
            }`}
            onClick={() => handleClick("admin_management")}
          >
            {lang.admin_management}
          </li>
        )}
        <li
          className={`p-4 cursor-pointer ${
            selected === "price_management" ? "bg-gray-700" : "hover:bg-gray-500"
          }`}
          onClick={() => handleClick("price_management")}
        >
          {lang.price_management}
        </li>
      </ul>
    </div>
  );
}

export default AdminLeftMenu;
