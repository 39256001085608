import Cache from "../../../classes/Cache";
import ThemeDispatch from "../../../interfaces/ThemeDispatch.interface";
import { LIGHT, LS } from "../../../utils/constants";
import { SET_THEME } from "../../actions/action.types";

const lsCache = new Cache(LS);

const initial = lsCache.get("theme") ?? LIGHT;

const theme = (state = initial, action: ThemeDispatch) => {
  const { type, payload } = action;
  if (type === SET_THEME) {
    // Store the theme state in local storage
    lsCache.save("theme", payload);
    return payload;
  }
  lsCache.save("theme", state);
  return state;
};

export default theme;
