import React from "react";
import { NAME } from "../../utils/constants";
import { BiSolidUser } from "react-icons/bi";

interface Props {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export default function Name({ name, onChange, placeholder }: Props) {
  return (
    <div className="relative">
      <input
        id={NAME}
        type="text"
        className="border border-gray-300 w-[100%] shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder={placeholder ?? "Name *"}
        onChange={onChange}
        value={name}
      />
      <BiSolidUser className="input-icon" />
    </div>
  );
}

// import React from "react";
// import { NAME } from "../../utils/constants";
// import { BiSolidUser } from "react-icons/bi";

// interface Props {
//   name: string;
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   placeholder?: string;
// }

// export default function Name({ name, onChange, placeholder }: Props) {
//   return (
//     <div className="relative">
//       <input
//         id={NAME}
//         type="text"
//         className="w-full px-4 py-2 text-gray-800 bg-white border border-gray-300 rounded-lg shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
//         placeholder={placeholder ?? "Name *"}
//         onChange={onChange}
//         value={name}
//       />
//       <BiSolidUser className="absolute left-3 top-2.5 text-gray-400" />
//     </div>
//   );
// }
