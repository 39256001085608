import React, { useEffect, useState } from "react";
import FormPriceData from "../../../interfaces/FormPriceData.interface";
import AdminHandler from "../../../classes/AdminHandler";
import { devLogger } from "../../../utils/logger.config";
import Loading from "../../Decorations/Loading";
import PriceTable from "../AdminComponent/PricingTable";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function PriceManagement() {
  const [formPriceData, setFormPriceData] = useState<FormPriceData | null>();
  const [isloading, setisloading] = useState(false);
  const [showtable, setshowtable] = useState(false);
  const [level, setLevel] = useState<string | null>(null);
  useEffect(() => {
    // Get the level from localStorage when the component mounts
    const storedLevel = localStorage.getItem("admin_level");
    setLevel(storedLevel);
  }, []);
  const GetAllPrice = async () => {
    setshowtable(true);
    try {
      setisloading(true);

      const res = await AdminHandler.handleFormPricingData();
      if (res) {
        setFormPriceData(res?.data);
      }
    } catch (error) {
      devLogger.error(error);
    } finally {
      setisloading(false);
    }
  };
  const handlePriceUpdate = () => {
    if (level === "regular") {
      toast.success(lang.deny_admin_permission);
      return;
    }
  };
  return (
    <div>
      <h1>{lang.price_management}</h1>
      <div className="mt-5">
        {showtable ? (
          <div className="mt-4">
            {isloading ? (
              <Loading />
            ) : (
              <>
                <PriceTable prices={formPriceData} onUpdate={handlePriceUpdate} />
                <button
                  onClick={() => setshowtable(false)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4"
                >
                  {lang.back}
                </button>
              </>
            )}
          </div>
        ) : (
          <button
            onClick={GetAllPrice}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            {lang.view_price}
          </button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default PriceManagement;
