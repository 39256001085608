import axiosInstance from "../axios.config";

// Function to handle document upload
export default async function deleteDocument(fileId: string) {
  try {
    const { data } = await axiosInstance.delete(`/auth/delete_file`, {
      params: { f: [fileId] },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (err) {
    throw err; // Handle error as needed
  }
}

// Function to handle document deletion for multiple file IDs
export async function deleteDocumentsByArray(fileIds: string[]) {
  try {
    const { data } = await axiosInstance.delete(`/auth/delete_file`, {
      params: { f: fileIds }, // Axios will automatically serialize the array as f[]=id1&f[]=id2
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (err) {
    throw err; // Handle error as needed
  }
}
