import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

// Function to handle document upload with raw JSON data
export default async function updateDocument(updatedData: { [key: string]: any }) {
  try {
    // Sending raw JSON data
    const { data } = await axiosInstance.put("/update_user_tax_details", updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data; // Return the response data
  } catch (err) {
    devLogger.error(err);
    throw err; // Propagate the error
  }
}
