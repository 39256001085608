import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import HomeInputProps from "../interfaces/HomeInputComponent.interface";

function HomeInputComponent({
  label,
  placeholder,
  Icon,
  required,
  value,
  name,
  onChange,
  password = false,
  disabled = false,
}: HomeInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`${!required && "md:mt-1"}`}>
      <div className={`${!required && "md:mb-2"} flex flex-row items-center gap-1`}>
        <label className="block text-sm font-medium " htmlFor={name}>
          {label}
        </label>
        {required && <span className="text-red-700 text-2xl">*</span>}
      </div>
      <div
        className={`relative   flex items-center border border-gray-300 rounded-[9px]  ${
          disabled && "bg-slate-200 "
        }`}
        // block w-full px-4 py-2 border dark:bg-slate-600 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] cursor-pointer"
        // disabled={disbled}
      >
        <span className="absolute inset-y-5 flex items-center pl-3 text-gray-500">
          <Icon className="h-6 w-6" />
        </span>

        <input
          type={
            password
              ? showPassword
                ? "text"
                : "password"
              : label.toLowerCase() === "dob"
              ? "date"
              : "text"
          }
          id={name}
          className=" block w-64 min-w-full pl-10 pr-3 outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
        />
        {password && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-3 flex items-center text-gray-500 focus:outline-none"
          >
            {showPassword ? <FiEyeOff className="h-6 w-6" /> : <FiEye className="h-6 w-6" />}
          </button>
        )}
      </div>
    </div>
  );
}

export default HomeInputComponent;

// mt-1 block w-64 min-w-full pl-10 pr-3 border-0 focus:border-transparent focus:outline-none focus:ring-0  => same but not responsive

// "mt-1 block w-full pl-10 pr-3 border-0 focus:border-transparent focus:outline-none focus:ring-0 =>responsive

// mt-1 block w-64 min-w-full pl-10 pr-3 border-0 focus:border-transparent focus:outline-none focus:ring-0
