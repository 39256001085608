// api/verifyEmail.ts
import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export const emailActivation = async (token: string) => {
  try {
    const response = await axiosInstance.get(`/activate/${token}`);

    return response; // Assuming the API sends back a response with the message
  } catch (err) {
   devLogger.error(err)
    throw err; // Propagate the error
    
  }

};
