import React from "react";
import HistoryPage from "../components/HistoryRelated/HistoryPage";
import { useLocation } from "react-router-dom";

function History() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get("q") || undefined; // If missing, q will be undefined
  const ty = queryParams.get("ty") || undefined; // If missing, ty will be undefined
  return (
    <>
      <div className="w-full flex justify-center mt-5 mb-4">
        <h1>History</h1>
      </div>
      <div>
        <HistoryPage q={q} ty={ty} />
      </div>
    </>
  );
}

export default History;
