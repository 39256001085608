import axiosInstance from "../axios.config";
import { devLogger, prodLogger } from "../logger.config";

export default async function updateEmail({
  new_email,
  verification_code,
}: {
  new_email: string;
  verification_code: string;
}) {
  try {
    devLogger.log("Calling the email update API...");
    const { data } = await axiosInstance.post("/update_email", { new_email, verification_code });
    devLogger.log("Received a 200 response from server!");

    return data;
  } catch (err) {
    prodLogger.error("Error in response!");
    throw err;
  }
}
