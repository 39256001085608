import Accordion from "./Accordian";

const faqData = [
  {
    question: "What is Tailwind CSS?",
    answer:
      "Tailwind CSS is a utility-first CSS framework for rapidly building custom designs directly in your markup.",
  },
  {
    question: "How does Tailwind differ from other CSS frameworks?",
    answer:
      "Unlike traditional CSS frameworks like Bootstrap, Tailwind provides utility classes that let you design your components without writing custom CSS.",
  },
  {
    question: "Is Tailwind CSS good for large projects?",
    answer:
      "Yes! Tailwind CSS is highly scalable and works great for both small and large projects, especially when paired with tools like PurgeCSS.",
  },
  {
    question: "Is Tailwind CSS good for large projects?",
    answer:
      "Yes! Tailwind CSS is highly scalable and works great for both small and large projects, especially when paired with tools like PurgeCSS.",
  },
  {
    question: "Is Tailwind CSS good for large projects?",
    answer:
      "Yes! Tailwind CSS is highly scalable and works great for both small and large projects, especially when paired with tools like PurgeCSS.",
  },
  {
    question: "Is Tailwind CSS good for large projects?",
    answer:
      "Yes! Tailwind CSS is highly scalable and works great for both small and large projects, especially when paired with tools like PurgeCSS.",
  },
  {
    question: "Is Tailwind CSS good for large projects?",
    answer:
      "Yes! Tailwind CSS is highly scalable and works great for both small and large projects, especially when paired with tools like PurgeCSS.",
  },
];
function FAQSection() {
  return (
    <div className="relative py-20 px-52 ">
      <img className="mb-6" src="/Assets/Line 3.svg" alt="" />
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4 w-[48%]">
          <img className="h-28 w-28" src="/Assets/FAQ.svg" alt="" />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-student-tax-secondary-theme-color font-bold text-4xl font-serif">
                Frequently
              </span>
              <span className="text-student-tax-secondary-theme-color font-bold text-4xl font-serif">
                Asked Question
              </span>
            </div>
            <div className="bg-student-tax-primary-theme-color w-36 flex items-center justify-center text-white-color py-2 px-2 rounded-3xl">
              See All FAQ's
            </div>
          </div>
        </div>
        <div className="w-[48%]">
          <Accordion items={faqData} />
        </div>
      </div>
    </div>
  );
}

export default FAQSection;
