import { SET_IS_AUTHENTICATED } from "../../actions/action.types";

interface Action {
  type: string;
  payload: boolean;
}

const isUserAuthenticated = (state = null, action: Action) => {
  const { type, payload } = action;
  if (type === SET_IS_AUTHENTICATED) return payload;
  return state;
};

export default isUserAuthenticated;
