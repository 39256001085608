import React from "react";
import { TbError404 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { HOME, LOGIN } from "../utils/routes";

export default function Page404() {
  return (
    <div className="page justify-center">
      <div className="flex flex-col items-center justify-center text-gray-400">
        <TbError404 className="text-[20rem]" />
        <h1 className="text-4xl">
          <em>Not what you are looking for?</em>
        </h1>
        <p className="p-6 font-semibold">
          <em>
            Try{" "}
            <span className="underline underline-offset-2 text-blue-500">
              <Link to={LOGIN}>signing in</Link>
            </span>{" "}
            or go back to{" "}
            <span className="underline underline-offset-2 text-blue-500">
              <Link to={HOME}>home</Link>
            </span>{" "}
            page
          </em>
        </p>
      </div>
    </div>
  );
}
