import axiosInstance from "../axios.config";
import { devLogger, prodLogger } from "../logger.config";

export default async function updatePassword({
  old_password,
  new_password,
}: {
  old_password: string;
  new_password: string;
}) {
  try {
    devLogger.log("Calling the password update API....");
    const { data } = await axiosInstance.post("/update_password", { old_password, new_password });
    devLogger.log("Success response from server!");

    return data;
  } catch (err) {
    prodLogger.error("Error in response!");
    throw err;
  }
}
