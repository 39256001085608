import React, { useEffect, useState } from "react";
import User from "../../../interfaces/AdminPanelUserDetails.interface";
import AdminHandler from "../../../classes/AdminHandler";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { devLogger } from "../../../utils/logger.config";

interface UserInspectProps {
  user: User; // Use the User interface that you've already defined
  year: number;
}
interface File {
  _id: string;
  file_type: string;
  filename_key: string;
}

function UserInspect({ user, year }: UserInspectProps) {
  const [userData, setUserData] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AdminHandler.handleUserInspect(year, user?._id);

        setUserData(res?.data);
      } catch (error) {
        devLogger.error(error);
      } finally {
      }
    };
    fetchData();
  }, [user]);
   const handleDownload = async (id: string) => {
   
     try {
       await AdminHandler.handleDocumentDownloadAdmin(id); // Call the handler function
     } catch (error) {
       devLogger.error(error);
     } finally {
      
     }
   };

  return (
    <div>
      {userData && (
        <div>
          <div className="flex gap-3 ">
            <span className=" w-52">{lang.first_name}</span>
            <span className="">{userData.first_name}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.middle_name}</span>
            <span className="">{userData.middle_name}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.last_name}</span>
            <span className="">{userData.last_name}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.occupation}</span>
            <span className="">{userData.occupation}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.dob}</span>
            <span className="">{userData.date_of_birth}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_add_foreign}</span>
            <span className="">{userData.mailing_add_foreign}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_address_line1}</span>
            <span className="">{userData.mailing_add_line1}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_address_line2}</span>
            <span className="">{userData.mailing_add_line2}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_add_city}</span>
            <span className="">{userData.mailing_add_city}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_add_state}</span>
            <span className="">{userData.mailing_add_state}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_add_zip}</span>
            <span className="">{userData.mailing_add_zip}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.mailing_add_country}</span>
            <span className="">{userData.mailing_add_country}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.tax_residency}</span>
            <span className="">{userData.tax_residency}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.tin_type}</span>
            <span className="">{userData.tin_type}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.tin}</span>
            <span className="">{userData.tin}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.itin_application}</span>
            <span className="">{userData.itin_application ? lang.yes : lang.no}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.digital_assets}</span>
            <span className="">{userData.digital_asset ? lang.yes : lang.no}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.foreign_country_account}</span>
            <span className="">{userData.foreign_country_account ? lang.yes : lang.no}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.additional_information}</span>
            <span className="">{userData.additional_info}</span>
          </div>
          <div className="flex gap-3">
            <span className=" w-52">{lang.submitted_at}</span>
            <span className=""> {new Date(userData.createdAt).toLocaleString()}</span>
          </div>
          {userData &&
            userData?.file_ids.map((file: File) => {
              return (
                <div className="flex gap-3">
                  <span className="w-52">{file.file_type}</span>
                  <span
                    onClick={() => handleDownload(file?._id)}
                    className="text-blue-500 underline cursor-pointer"
                  >
                    {file.filename_key}
                  </span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default UserInspect;
