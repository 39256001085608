import { devLogger } from "../utils/logger.config";

export const checkInternetConnection = async () => {
  // First check using navigator.onLine
  if (!navigator.onLine) {
    return false;
  }

  // If navigator says we are online, make a fetch request
  try {
    const response = await fetch("https://www.google.com/favicon.ico", {
      method: "HEAD",
      mode: "no-cors",
    });
    // If the response status is 0 or the request is successful, we have a connection
    return response.ok || response.status === 0;
  } catch (error) {
     devLogger.error(error)// Log any error
    return false; // Connection failed
  }
};
