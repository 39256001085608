import React, { useEffect, useState } from "react";
import lang from "../../utils/LanguagePacks/en-us.json"
import { COOKIE_POLICY_DISMISS } from "../../utils/constants";
const CookiePolicy: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  useEffect(() => {
    const hasDismissed = localStorage.getItem(COOKIE_POLICY_DISMISS); 

    if (hasDismissed) {
      setIsVisible(false); 
    } else {
      setIsVisible(true); 
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem(COOKIE_POLICY_DISMISS, "true"); 
    setIsVisible(false); 
  };

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    } else {
      setTimeout(() => setIsAnimating(false), 500); 
    }
  }, [isVisible]);

  

  return (
    <>
      {isVisible && isAnimating && (
        <div className=" flex justify-center">
          <div
            className={`fixed bottom-0  bg-gray-800 dark:bg-slate-200 text-white p-4 flex gap-3 items-center shadow-lg z-50 transition-transform duration-500 ease-out ${
              isAnimating ? "translate-y-0" : "translate-y-full  "
            }`}
          >
            <span className="text-sm dark:text-black">{lang.cookies_policies}</span>
            <div>
              <button
                className="bg-taxeve-primary-blue hover:bg-taxeve-secondary-blue  text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                onClick={handleDismiss}
              >
                {lang.dismiss}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiePolicy;
