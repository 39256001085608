import { SS } from "../utils/constants";
import Cache from "./Cache";
import Cookie from "./Cookie";

export default class Token {
  static saveAccessToken(name: string, token: string) {
    Cookie.setCookie(name, token);
  }

  static saveRefreshToken(name: string, token: string) {
    const ssCache = new Cache(SS);
    ssCache.save(name, token);
  }
}
