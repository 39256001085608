import React from "react";
import lang from "../../utils/LanguagePacks/en-us.json";
import WhyToChooseCard from "./WhyToChooseCard";
function WhyToChoose() {
  return (
    <div className="px-52 flex flex-col items-center bg-why-to-choose-background ">
      <div className="mt-16 flex flex-col items-center gap-2 mb-16">
        <div className="flex flex-row gap-7">
          <hr className="border-t-2 border-student-tax-secondary-theme-color w-44 my-6" />

          <h2 className="text-5xl font-semibold text-student-tax-primary-theme-color font-mono ">
            {lang.why_to_choose}
          </h2>
          <hr className="border-t-2 border-student-tax-secondary-theme-color w-44 my-6" />
        </div>
        <p className="text-text-gray text-xl font-sans">{lang.why_to_choose_desc}</p>
      </div>
      <div className="flex flex-row justify-between w-full mb-14">
        <WhyToChooseCard
          value="Lorem Ipsum"
          description="Various personalized high-speed data packages to enjoy seamless Internet access"
          image="/Assets/whyChooseUs.svg"
        />
        <WhyToChooseCard
          value="Lorem Ipsum"
          description="Various personalized high-speed data packages to enjoy seamless Internet access"
          image="/Assets/whyChooseUs.svg"
        />
        <WhyToChooseCard
          value="Lorem Ipsum"
          description="Various personalized high-speed data packages to enjoy seamless Internet access"
          image="/Assets/whyChooseUs.svg"
        />
      </div>
    </div>
  );
}

export default WhyToChoose;
