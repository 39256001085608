import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export default async function refreshTokens({
  refresh_token,
  access_token,
}: {
  refresh_token: string;
  access_token: string;
}) {
  try {
    devLogger.log("Calling the refresh token API...");
    const { data } = await axiosInstance.post("/refresh_tokens", {
      refresh_token,
      access_token,
    });

    devLogger.log("Response received from backend!");
    devLogger.log(data);

    return data;
  } catch (err) {
    throw err;
  }
}
