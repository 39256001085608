import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { EMAIL, PASSWORD, SS } from "./constants";
import Cache from "../classes/Cache";
import AdminAuth from "../classes/AdminAuth";
import LoginData from "../interfaces/LoginData.interface";
import Validator from "../classes/Validator";

export const useShowPassword = (
  initial: boolean
): [showPassword: boolean, toShowPassword: React.Dispatch<React.SetStateAction<boolean>>] => {
  const [showPassword, toShowPassword] = useState(initial);
  return [showPassword, toShowPassword];
};

export const useAutoEnabler = (
  initialState: boolean,
  updateState: React.Dispatch<React.SetStateAction<boolean>>,
  timeout: number
): number => {
  // const [isDisabled, toDisable] = useState(initialState);
  const isDisabled = initialState;
  const toDisable = updateState;
  const [counter, setCounter] = useState(timeout);

  useMemo(() => {
    if (isDisabled && counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [isDisabled, counter]);

  useMemo(() => {
    if (counter <= 0) {
      toDisable(false);
      setCounter(timeout);
    }
  }, [counter, toDisable, timeout]);

  return counter;
};

export const useUserDetail = (): { username: string; email: string; phone: number } => {
  const [userDetail, setUserDetail] = useState({
    username: "",
    email: "",
    phone: 0,
  });

  useEffect(() => {
    const getUserDetail = () => {
      const sscache = new Cache(SS);
      const userDetailStr = sscache.get("user_detail");

      if (userDetailStr) {
        const userDetail: { username: string; email: string; phone: number } =
          JSON.parse(userDetailStr);
        setUserDetail(userDetail);
      }
    };
    getUserDetail();
  }, []);

  return userDetail;
};

export const useAdminAuthentication = () => {
  const [adminAuthenticated, isAdminAuthenticated] = useState(false);

  useLayoutEffect(() => {
    AdminAuth.setAuthenticateAdmin(isAdminAuthenticated);

    return () => AdminAuth.setAuthenticateAdmin(isAdminAuthenticated);
  }, []);

  useLayoutEffect(() => {
    const adminAuth = new AdminAuth();
    adminAuth.verify();

    return () => isAdminAuthenticated(false);
  }, []);

  return { adminAuthenticated, isAdminAuthenticated };
};

export const useLogin = (loginHelper: (email: string, password: string) => void) => {
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [showErrorIndicator, toShowErrorIndicator] = useState({
    onEmail: false,
    onPass: false,
  });

  // Handle change event
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id: type, value } = e.currentTarget;

      switch (type) {
        case EMAIL:
          // Remove any existing err indicators
          toShowErrorIndicator({
            ...showErrorIndicator,
            onEmail: false,
          });
          setLoginData({
            ...loginData,
            email: value,
          });
          break;

        case PASSWORD:
          // Remove any existing err indicators
          toShowErrorIndicator({
            ...showErrorIndicator,
            onPass: false,
          });
          setLoginData({
            ...loginData,
            password: value,
          });
          break;

        default:
          break;
      }
    },
    [showErrorIndicator, loginData]
  );

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email
    const isValidEmail = Validator.validateEmail(loginData.email);
    const passwordExists = loginData.password.length > 0;

    if (!isValidEmail && !passwordExists) {
      // Show error indicators
      toShowErrorIndicator({
        onEmail: true,
        onPass: true,
      });
      return;
    } else if (!isValidEmail) {
      toShowErrorIndicator({ ...showErrorIndicator, onEmail: true });
      return;
    } else if (!passwordExists) {
      toShowErrorIndicator({ ...showErrorIndicator, onPass: true });
      return;
    }

    // Handle login
    loginHelper(loginData.email, loginData.password);
  };

  return { loginData, handleChange, handleLogin, showErrorIndicator };
};
