import axiosInstance from "../axios.config";

export default async function GetHome(year: number) {
  try {
    const { data } = await axiosInstance.get(`/home/${year}`);
    return data;
  } catch (err: any) {
    throw err;
  }
}
