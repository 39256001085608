import React, { useState, createContext } from "react";
import { FaUser, FaSignature, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { devLogger } from "../utils/logger.config";
import UsernameUpdate from "../components/ProfileRelated/UpdateUsername/UsernameUpdate";
import EmailUpdate from "../components/ProfileRelated/UpdateEmail/EmailUpdate";
import PhoneUpdate from "../components/ProfileRelated/UpdatePhone/PhoneUpdate";
import PasswordUpdate from "../components/ProfileRelated/UpdatePassword/PasswordUpdate";
import { useUserDetail } from "../utils/customHooks";

export const UserDetailContext = createContext({
  username: "",
  email: "",
  phone: 0,
});

const settingsMap: any = {
  "0": <UsernameUpdate />,
  "1": <EmailUpdate />,
  "2": <PhoneUpdate />,
  "3": <PasswordUpdate />,
};

export default function ProfileSettings() {
  const [currentSelectedSetting, setCurrentSelectedSetting] = useState("0"); // Set the first setting option as the current selected setting
  const userDetail = useUserDetail();

  // Handle selecting a setting
  const selectSetting = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id: idx } = e.currentTarget;
    devLogger.log(`Clicked idx: ${idx}`);

    setCurrentSelectedSetting(idx);
  };

  return (
    <UserDetailContext.Provider value={userDetail}>
      <div className="h-[100vh] page">
        <div className="flex h-[100%]">
          <section className="w-[20rem] border-r border-l tb-border pt-6">
            <h2 className="border-b tb-border flex items-center gap-4 px-4 py-4">
              <FaUser /> Profile Settings
            </h2>

            <ul>
              <li
                onClick={selectSetting}
                id={"0"}
                className={`name-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "0" &&
                  "bg-student-tax-primary-theme-color cursor-pointer text-white-color"
                } `}
              >
                <FaSignature />
                Username
              </li>
              <li
                onClick={selectSetting}
                id={"1"}
                className={`email-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "1" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <MdEmail />
                Email
              </li>
              <li
                onClick={selectSetting}
                id={"2"}
                className={`phone-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "2" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <FaPhoneAlt />
                Phone
              </li>
              <li
                onClick={selectSetting}
                id={"3"}
                className={`password-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "3" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <RiLockPasswordFill />
                Password
              </li>
            </ul>
          </section>
          <section className="w-[100%] px-4 py-6">{settingsMap[currentSelectedSetting]}</section>
        </div>
      </div>
    </UserDetailContext.Provider>
  );
}
