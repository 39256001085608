import React from "react";
interface RadioProps {
  options: string[]; // Array of options for the radio buttons
  selectedOption: string; // The currently selected option
  name: string; // Key name for the object in state
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lebel: string;
  required: boolean;
  disabled?: boolean;
}
function YesNoRadioButton({
  options,
  selectedOption,
  name,
  onChange,
  lebel,
  required,
  disabled = false,
}: RadioProps) {
  return (
    <div className=" md:flex-row flex items-center justify-between xs:flex-col ">
      <label className=" md:w-1/2 w-full  text-sm font-medium ">
        {lebel}
        {required && <span className="text-red-700 text-xl">*</span>}
      </label>
      <div className="flex items-center xs:justify-between xs:w-full md:w-auto xs:flex-row ">
        {options.map((option) => (
          <div>
            <label className={` mr-4 ${disabled && "text-slate-400"}`}>
              <input
                type="radio"
                name={name}
                value={option}
                checked={selectedOption === option}
                onChange={onChange}
                className="mr-2"
                disabled={disabled}
              />
              <span>{option}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default YesNoRadioButton;
