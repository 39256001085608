import React, { useState } from "react";
import MainImg from "../components/MainImg";
import VerifyEmail from "../components/AuthRelated/VerifyEmail";
import LoginForm from "../components/AuthRelated/LoginForm";
import { EMAIL, PASSWORD } from "../utils/constants";
import Validator from "../classes/Validator";
import LoginData from "../interfaces/LoginData.interface";
import Handler from "../classes/Handler";
import { SHA256 } from "crypto-js";
import { devLogger } from "../utils/logger.config";
import { logEvent } from "../googleAnalytics/ga";
import lang from "../utils/LanguagePacks/en-us.json";
import { actions, category, labels } from "../utils/CustomAnalytics";
import ProgressBar from "../components/ProgressBar";

export default function Login() {
  const [notifyToVerifyEmail, toNotifyToVerifyEmail] = useState(false);
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [showErrorIndicator, toShowErrorIndicator] = useState({
    onEmail: false,
    onPass: false,
  });

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case EMAIL:
        // Remove any existing err indicators
        toShowErrorIndicator({
          ...showErrorIndicator,
          onEmail: false,
        });
        setLoginData({
          ...loginData,
          email: value,
        });
        break;

      case PASSWORD:
        // Remove any existing err indicators
        toShowErrorIndicator({
          ...showErrorIndicator,
          onPass: false,
        });
        setLoginData({
          ...loginData,
          password: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle login by form submission
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email
    const isValidEmail = Validator.validateEmail(loginData.email);
    const passwordExists = loginData.password.length > 0;

    if (!isValidEmail && !passwordExists) {
      // Show error indicators
      toShowErrorIndicator({
        onEmail: true,
        onPass: true,
      });
      return;
    } else if (!isValidEmail) {
      toShowErrorIndicator({ ...showErrorIndicator, onEmail: true });
      return;
    } else if (!passwordExists) {
      toShowErrorIndicator({ ...showErrorIndicator, onPass: true });
      return;
    }

    // Handle user login
    const hash: string = SHA256(process.env.REACT_APP_NONCE + loginData.password).toString();
    devLogger.log(`Hashed password: ${hash}`);
    const updatedData: LoginData = {
      ...loginData,
      password: hash,
    };
    try {
      await Handler.handleLogin(updatedData);
      logEvent(category.auth, actions.login_success);
    } catch (errStatus: any) {
      devLogger.error(errStatus);
      if (errStatus === 400) {
        logEvent(category.auth, actions.login_failed, labels.wrong_creadentials);
      }
      if (errStatus === 500) {
        logEvent(category.auth, actions.login_failed, labels.server_error);
      }

      if (errStatus === 403) {
        logEvent(category.auth, actions.login_failed, labels.non_verified_email);
        try {
          await Handler.handleEmailVerification(loginData.email);
          toNotifyToVerifyEmail(true);
        } catch (errstatus) {
          devLogger.error(errStatus);
        }
      }
    }
  };

  return (
   
    <div className=" flex flex-col justify-center items-center mt-5">
      <div className="w-3/5">
        <ProgressBar />
      </div>
      <div className="md:w-1/2 w-full px-2">
        {!notifyToVerifyEmail ? (
          <LoginForm
            email={loginData.email}
            password={loginData.password}
            onChange={handleChange}
            onSubmit={handleLogin}
            showIndicator={showErrorIndicator}
          />
        ) : (
          <VerifyEmail useremail={loginData.email} />
        )}
      </div>
    </div>
  );
}
