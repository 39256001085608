import React, { useEffect, useState } from "react";
import HomeInputComponent from "../../HomeInputComponent";
import { BiLock, BiMailSend, BiPhone, BiUserPin } from "react-icons/bi";
import lang from "../../../utils/LanguagePacks/en-us.json";
import AdminManagementData from "../../../interfaces/AdminManagementData.interface";
import Dropdown from "../../Dropdown";
import { validateCreateAdminForm } from "../../../classes/CreateAdminFormValidate";
import { SHA256 } from "crypto-js";
import AdminHandler from "../../../classes/AdminHandler";
import { devLogger } from "../../../utils/logger.config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Admin from "../../../interfaces/GetAdminsData.interface";
import AdminTable from "../AdminComponent/AdminTable";
import Loading from "../../Decorations/Loading";
function AdminManagement() {
  const [data, setData] = useState<AdminManagementData>({
    email: "",
    password: "",
    name: "",
    phone: "",
    level: {
      level_id: "",
      level_name: "",
    },
  });
  const AdminLevel = [
    { name: "Regular", code: "regular" },
    { name: "Master", code: "master" },
  ];
  const [level, setLevel] = useState<string | null>(null); // State to hold the 'level' value
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [adminsList, setadminsList] = useState<Admin[] | null>();
  const [adminListView, setadminListView] = useState(false);
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    // Get the level from localStorage when the component mounts
    const storedLevel = localStorage.getItem("admin_level");
    setLevel(storedLevel);
  }, []);

  const handleData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAdminLevelSelection = (selectedOption: { name: string; code: string }) => {
    setData((prev) => ({
      ...prev,
      level: {
        level_name: selectedOption.name,
        level_id: selectedOption.code,
      },
    }));
  };
  const handleCreateAdmin = async () => {
    const errors = validateCreateAdminForm(data);
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
      // Handle errors (e.g., show them in UI)
    } else {
      // Proceed with form submission (e.g., API call)
      try {
        // Hash the password
        const hash: string = SHA256(process.env.REACT_APP_NONCE + data.password).toString();

        const updatedAdminData = {
          ...data,
          password: hash,
          phone: +data.phone,
          level: data.level.level_id,
        };
        const res = await AdminHandler.handleCreateAdmin(updatedAdminData);
        if (res?.message) {
          toast.success(res?.message);
        }
      } catch (error: any) {
        devLogger.error(error);
        toast.error(error?.Error);
      }
    }
  };
  const handleGetAllAdmin = async () => {
    try {
      setadminListView(true);
      setisloading(true);
      const res = await AdminHandler.getAllAdminsList();
      if (res?.Message) {
        setadminsList(res?.admins);
      }
    } catch (error) {
      setadminsList(null);
      alert(lang.try_again);
    } finally {
      setisloading(false);
    }
  };
  const handleAdminDelete = async (id: string) => {
    devLogger.log(`${id}Admin was deleted`);
    try {
      const res = await AdminHandler.handleAdminDelete(id);
      if (res?.Message) {
        setadminsList((prevList) => (prevList ?? []).filter((admin) => admin._id !== id));
      }
    } catch (error) {
      devLogger.error(error);
    }
  };
  return (
    <>
      {adminListView ? (
        <div>
          {adminsList && (
            <>
              <button
                disabled={level !== "master"}
                className={`w-60 self-center ${
                  level !== "master"
                    ? "bg-slate-500"
                    : "bg-taxeve-secondary-blue hover:bg-taxeve-primary-blue"
                } text-white font-semibold py-2 rounded-md  transition mb-5`}
                onClick={() => setadminListView(false)}
              >
                {lang.create_an_admin}
              </button>
              {isloading ? (
                <Loading />
              ) : (
                <AdminTable admins={adminsList} onDelete={handleAdminDelete} />
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className="mb-10">
            <h1>{lang.create_an_admin}</h1>
            <div className=" flex-col md:flex-row mb-4 flex gap-3 md:items-center ">
              <div className="flex flex-col mb-4 h-20">
                <HomeInputComponent
                  label={lang.name}
                  placeholder={lang.name}
                  Icon={BiUserPin}
                  required={true}
                  value={data?.name}
                  name="name"
                  onChange={handleData}
                />
                {errors?.name && <span className="text-red-700">{errors?.name}</span>}
              </div>

              <div className="flex flex-col mb-4 h-20">
                <HomeInputComponent
                  label={lang.phone}
                  placeholder={lang.phone}
                  Icon={BiPhone}
                  required={true}
                  value={data?.phone}
                  name="phone"
                  onChange={handleData}
                />

                {errors?.phone && <span className="text-red-700">{errors?.phone}</span>}
              </div>
            </div>
            <div className=" flex-col md:flex-row mb-4 flex gap-3 md:items-center  ">
              <div className="flex flex-col mb-4 h-20">
                <HomeInputComponent
                  label={lang.email}
                  placeholder={lang.email}
                  Icon={BiMailSend}
                  required={true}
                  value={data?.email}
                  name="email"
                  onChange={handleData}
                />
                {errors?.email && <span className="text-red-700">{errors?.email}</span>}
              </div>
              <div className="flex flex-col mb-4 h-20">
                <HomeInputComponent
                  label={lang.password}
                  placeholder={lang.password}
                  Icon={BiLock}
                  required={true}
                  value={data?.password}
                  name="password"
                  onChange={handleData}
                  password={true}
                />
                {errors?.password && <span className="text-red-700">{errors?.password}</span>}
              </div>
            </div>
            <div className=" flex-col md:flex-row mb-4 flex gap-3 md:items-center ">
              <div className="flex flex-col mb-4">
                <Dropdown
                  options={AdminLevel}
                  label={lang.admin_level}
                  onChange={handleAdminLevelSelection}
                />
                {errors?.level && <span className="text-red-700">{errors?.level}</span>}
              </div>
            </div>

            <button
              disabled={level !== "master"}
              className={`w-60 self-center ${
                level !== "master"
                  ? "bg-slate-500"
                  : "bg-taxeve-secondary-blue hover:bg-taxeve-primary-blue"
              } text-white font-semibold py-2 rounded-md  transition`}
              onClick={handleCreateAdmin}
            >
              {lang.create_an_admin}
            </button>
          </div>
          <button
            disabled={level !== "master"}
            className={`w-60 self-center ${
              level !== "master"
                ? "bg-slate-500"
                : "bg-taxeve-secondary-blue hover:bg-taxeve-primary-blue"
            } text-white font-semibold py-2 rounded-md  transition mb-3`}
            onClick={handleGetAllAdmin}
          >
            {lang.see_all_admin}
          </button>
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default AdminManagement;
