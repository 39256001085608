import FormData from "../interfaces/FormData.interface";

const resetFormData = (formData: FormData): FormData => {
  const resetState: FormData = { ...formData }; // Start with a copy of the original state

  Object.keys(resetState).forEach((key) => {
    const value = resetState[key as keyof FormData];

    if (typeof value === "string") {
      // Reset string values to empty string
      resetState[key as keyof FormData] = "" as any;
    } else if (value === null || value instanceof File) {
      // Reset File or null fields to null
      resetState[key as keyof FormData] = null as any;
    } else if (typeof value === "object" && value !== null) {
      // Handle nested objects (e.g., state and country)
      resetState[key as keyof FormData] = Object.keys(value).reduce((nestedAcc, nestedKey) => {
        (nestedAcc as any)[nestedKey] = ""; // Reset each property to an empty string
        return nestedAcc;
      }, {} as typeof value) as any;
    }
  });

  return resetState;
};

export default resetFormData;
