import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

// Function to handle paynow
export default async function PaymentNow(Data: { [key: string]: any }) {
  try {
    // Sending raw JSON data
    const { data } = await axiosInstance.post("/pay", Data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data; // Return the response data
  } catch (err) {
    devLogger.error(err);
    throw err; // Propagate the error
  }
}
