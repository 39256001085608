import React from "react";
import { EMAIL, PHONE } from "../utils/constants";
import { MdEmail } from "react-icons/md";
import { AiTwotonePhone } from "react-icons/ai";

export default function ContactCred() {
  return (
    <div className="flex gap-6">
      <div id={EMAIL} className="flex items-center gap-2">
        <MdEmail className="text-xl text-white-color" />
        <p className="text-white-color font-semibold  text-lg font-sans">support@taxeve.com</p>
      </div>
      <div id={PHONE} className="flex items-center gap-2">
        <AiTwotonePhone className="text-xl text-white-color" />
        <p className="text-white-color font-semibold  text-lg font-sans">(214) 884-8816</p>
      </div>
    </div>
  );
}
