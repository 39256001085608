export default class RegexPattern {
  static EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/;

  //   Min 9 chars, 1 uppercase letter, 1 smallcase letter, 1 special char
  static PASSWORD_REGEX =
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,20}$/;

  static NUMBER_ONLY_REGEX = /^\d+$/;

  // static PHONE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/;
}
