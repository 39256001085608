import { prodLogger } from "../utils/logger.config";
import RegexPattern from "./RegexPatterns";

export default class Validator {
  static validatePassword(password: string) {
    try {
      // Valid password criteria: Min 9 chars, 1 uppercase letter, 1 smallcase letter, 1 special char
      const isValidPass = new RegExp(RegexPattern.PASSWORD_REGEX).test(password);
      return isValidPass;
    } catch (err) {
      prodLogger.error(err);
    }
  }

  static validateEmail(email: string) {
    try {
      const isValidEmail = new RegExp(RegexPattern.EMAIL_REGEX).test(email);
      return isValidEmail;
    } catch (err) {
      prodLogger.error(err);
    }
  }

  static validatePhone(phone: number) {
    try {
      const isValidPhone = phone.toString().length === 10;
      return isValidPhone;
    } catch (err) {
      prodLogger.error(err);
    }
  }

  static validateNumberOnly(string: string) {
    const isValid = new RegExp(RegexPattern.NUMBER_ONLY_REGEX).test(string);
    return isValid;
  }
}
