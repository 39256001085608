import { Dispatch } from "@reduxjs/toolkit";
import { ScreenDisabler } from "../../classes/StateSetter";
import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";
import Handler from "../../classes/Handler";
import Cookie from "../../classes/Cookie";
import AdminAuth from "../../classes/AdminAuth";
import { SHA256 } from "crypto-js";

export default class AdminLogin {
  private email: string;
  private password: string;

  private static dispatch: Dispatch;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

  public async login(): Promise<void> {
    try {
      ScreenDisabler.enable(AdminLogin.dispatch);
      const hash: string = SHA256(process.env.REACT_APP_ADMIN_NONCE + this.password).toString();
      const { data } = await axiosInstance.post("/admin/login", {
        email: this.email,
        password: hash,
      });
      devLogger.log({ response_after_admin_login: data });

      // Save the tokens
      Cookie.setCookie("admin_access_token", data.adminAccessToken); // Store the access token in cookie
      localStorage.setItem("admin_refresh_token", data.adminRefreshToken); // Store the refresh token in local storage
      localStorage.setItem("admin_level", data.adminLevel); // Store the refresh token in local storage

      // Re-verify the admin
      new AdminAuth().verify();

      ScreenDisabler.disable(AdminLogin.dispatch);
    } catch (err: any) {
      devLogger.error(err);
      Handler.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(AdminLogin.dispatch);
      });
    }
  }

  // Setters
  public static setDispatch(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }
}
