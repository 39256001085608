import { Dispatch } from "@reduxjs/toolkit";
import {
  SET_IS_AUTHENTICATED,
  SET_THEME,
  SET_TOAST_MESSAGE,
  TO_DISABLE_SCREEN,
} from "../redux/actions/action.types";
import ThemeDispatch from "../interfaces/ThemeDispatch.interface";
import ToastMessageObj from "../interfaces/ToastMessageObj.interface";
import { ToastMessageDispatch } from "../interfaces/ToastMessageDispatch.interface";
import DisableScreenDispatch from "../interfaces/DisableScreenDispatch.interface";

export default class StateSetter {
  static setIsUserAuthenticated(isAuthenticated: boolean, dispatch: any) {
    dispatch({
      type: SET_IS_AUTHENTICATED,
      payload: isAuthenticated,
    });
  }

  static setTheme(theme: string, dispatch: Dispatch<ThemeDispatch>) {
    dispatch({
      type: SET_THEME,
      payload: theme,
    });
  }

  static setToastMessage(
    toastMessageObj: ToastMessageObj,
    dispatch: Dispatch<ToastMessageDispatch>
  ) {
    dispatch({
      type: SET_TOAST_MESSAGE,
      payload: {
        message: toastMessageObj.message,
        type: toastMessageObj.type,
        toDisplay: toastMessageObj.toDisplay,
      },
    });

    // Remove the toast message based on the timeout calculated based on the message length
    const timeout = toastMessageObj.message.length * 100; // ms
    setTimeout(() => {
      this.removeToastMessage(dispatch);
    }, timeout);
  }

  static removeToastMessage(dispatch: Dispatch<ToastMessageDispatch>) {
    dispatch({
      type: SET_TOAST_MESSAGE,
      payload: {
        message: "",
        type: "",
        toDisplay: false,
      },
    });
  }

  static toDisableScreen(payload: boolean, dispatch: Dispatch<DisableScreenDispatch>) {
    dispatch({
      type: TO_DISABLE_SCREEN,
      payload: payload,
    });
  }
}

export class ScreenDisabler extends StateSetter {
  static enable(dispatch: Dispatch<DisableScreenDispatch>) {
    this.toDisableScreen(true, dispatch);
  }
  static disable(dispatch: Dispatch<DisableScreenDispatch>) {
    this.toDisableScreen(false, dispatch);
  }
}
