import LoginData from "../../interfaces/LoginData.interface";
import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export default async function login(loginData: LoginData) {
  try {
    const { data } = await axiosInstance.post("/login", loginData);
    devLogger.log("Response from backend after calling the login API");
    devLogger.log(data);
    return data;
  } catch (err) {
    devLogger.error(err);
    throw err;
  }
}
