// src/api/getUserData.ts
import axiosInstance from "../axios.config";

export default async function InspectUser(year: number,id:string) {
  try {
    const { data } = await axiosInstance.get(
      `admin/auth/get_user_tax_details/${id},${year}`,
      
    ); 
    return data;
  } catch (err) {
    throw err; 
  }
}
