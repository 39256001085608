import axiosInstance from "../axios.config";

export default async function getFormPricingData() {
  try {
    const { data } = await axiosInstance.get(`/admin/auth/get_price`);
    return data;
  } catch (err) {
    throw err;
  }
}
