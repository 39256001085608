import React from "react";
import { PHONE } from "../../utils/constants";
import Formatter from "../../classes/Formatter";
import { AiTwotonePhone } from "react-icons/ai";

interface Props {
  phone: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export default function Phone({ phone, onChange, placeholder }: Props) {
  return (
    <div className="relative">
      <input
        id={PHONE}
        type="text"
        className="border border-gray-300 w-[100%] shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder={placeholder ?? "Phone *"}
        onChange={onChange}
        value={phone ? Formatter.formatPhone(1, phone.toString()) : ""}
      />
      <AiTwotonePhone className="input-icon" />
    </div>
  );
}
