import React, { useState, useEffect } from "react";
import ProfileInfoPopup from "./ProfileInfoPopup";
import { PROFILE_ICON } from "../../utils/constants";
import { devLogger } from "../../utils/logger.config";
import { Link } from "react-router-dom";
import { CART } from "../../utils/routes";
import { BiCart, BiHistory, BiUser } from "react-icons/bi";
import ThemeToggler from "../ThemeToggler";

interface Props {
  username: string;
  email: string;
}

export default function ProfilePanel({ username, email }: Props) {
  const [displayPopup, toDisplayPopup] = useState(false);

  // Handle click
  useEffect(() => {
    const handleClick = (e: any) => {
      const id = e.target.id;
      devLogger.log(`Clicked on: ${id}`);

      if (id && id === PROFILE_ICON) toDisplayPopup(!displayPopup);
      else toDisplayPopup(false);
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [displayPopup]);

  return (
    <div className="flex flex-row items-center gap-x-3">
      <Link to="/history">
        
        <div className="flex h-10 w-10 rounded-full justify-center items-center border border-student-tax-secondary-theme-color bg-white-color">
          <BiHistory className="text-2xl cursor-pointer fa-solid fa-circle-user text-student-tax-secondary-theme-color" />
        </div>
      </Link>
      <Link to={CART}>
        <div className="flex h-10 w-10 rounded-full justify-center items-center border border-student-tax-secondary-theme-color bg-white-color">
          <BiCart className="text-2xl cursor-pointer fa-solid fa-circle-user text-student-tax-secondary-theme-color" />
        </div>
      </Link>

      <div className="relative">
        <div className="flex h-10 w-10 rounded-full justify-center items-center border border-student-tax-secondary-theme-color bg-white-color">
          <BiUser
            id={PROFILE_ICON}
            className="text-2xl cursor-pointer fa-solid fa-circle-user text-student-tax-secondary-theme-color"
          />
        </div>
        {displayPopup && <ProfileInfoPopup username={username} email={email} />}
      </div>
      <ThemeToggler />
    </div>
  );
}
