import React, { useEffect, useRef, useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import lang from "../utils/LanguagePacks/en-us.json";

interface Option {
  name: string;
  code: string;
}

interface CustomDropdownProps {
  options: Option[];
  label?: string;
  onChange: (value: Option) => void;
  disabled?: boolean;
  value?: string;
  required?: boolean;
  codeShow?: boolean;
}

function Dropdown({
  options,
  label,
  onChange,
  disabled = false,
  value = "",
  required = true,
  codeShow = true,
}: CustomDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [debouncedInput, setDebouncedInput] = useState(inputValue);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle input changes and open dropdown on editing
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(true); // Ensure dropdown opens while editing
    setActiveIndex(-1); // Reset active index when input changes
  };

  // Handle input click to open dropdown correctly on first click
  const handleInputClick = () => {
    if (!isOpen) {
      setIsOpen(true); // Ensure dropdown opens on the first click
    }
  };

  // Debounce input for filtering options
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedInput(inputValue);
    }, 500); // 0.5s debounce
    return () => clearTimeout(timer);
  }, [inputValue]);

  // Filter options based on the debounced input
  useEffect(() => {
    if (debouncedInput) {
      const filtered = options.filter(
        (option) =>
          option.name.toLowerCase().startsWith(debouncedInput.toLowerCase()) ||
          `${option.name} (${option.code})`.toLowerCase().startsWith(debouncedInput.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options); // Show all options if no input
    }
  }, [debouncedInput, options]);

  const handleOptionClick = (option: Option) => {
    setInputValue(`${option.name} (${option.code})`);
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen || filteredOptions.length === 0) return;

    if (e.key === "ArrowDown") {
      setActiveIndex((prevIndex) => (prevIndex + 1) % filteredOptions.length);
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prevIndex) => (prevIndex <= 0 ? filteredOptions.length - 1 : prevIndex - 1));
    } else if (e.key === "Enter" && activeIndex >= 0) {
      e.preventDefault(); // Prevent form submission
      handleOptionClick(filteredOptions[activeIndex]);
    }
  };

  useEffect(() => {
    if (activeIndex >= 0 && optionRefs.current[activeIndex]) {
      optionRefs.current[activeIndex]?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [activeIndex]);

  // Open dropdown when input is focused via Tab key or clicked
  const handleInputFocus = () => {
    setIsOpen(true);
  };

  return (
    <div ref={dropdownRef} className="relative">
      {label && (
        <div className="flex flex-row items-center gap-1">
          <label className="block text-sm font-medium" htmlFor={label.toLowerCase()}>
            {label}
          </label>
          {required && <span className="text-red-700 text-2xl">*</span>}
        </div>
      )}

      <div
        className={`relative mt-1 flex items-center border border-gray-300 rounded-[9px] shadow-sm focus-within:ring focus-within:ring-blue-300 ${
          disabled && "bg-slate-200"
        }`}
      >
        <span className="absolute -right-0 pr-2 inset-y-6 flex items-center pl-3 text-gray-500">
          <BiCaretDown className="h-6 w-6" />
        </span>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleInputClick}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus} // Open dropdown on focus
          className=" shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] block w-64 min-w-full pl-3 pr-3 outline-none ring-blue-300 focus:ring-1 focus:border-none border-none cursor-pointer"
          placeholder={lang.tts}
          disabled={disabled}
        />
      </div>

      {isOpen && (
        <ul className="z-30 absolute shadow-lg left-0 right-0 bg-white dark:bg-gray-600 border mt-1 max-h-40 overflow-y-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                // key={option.code}
                ref={(el) => (optionRefs.current[index] = el)}
                className={`text-light-black font-semibold font-sans cursor-pointer p-2 hover:bg-gray-200 ${
                  index === activeIndex ? "bg-gray-200" : ""
                }`}
                onMouseEnter={() => setActiveIndex(index)}
                onClick={() => handleOptionClick(option)}
              >
                {codeShow ? `${option.name} (${option.code})` : option.name}
              </li>
            ))
          ) : (
            <li className="p-2 text-gray-500">{lang.no_result_found}</li>
          )}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
