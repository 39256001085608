import React, { useState } from "react";
import VerifyEmailLogo from "../Decorations/VerifyEmailLogo";
import lang from "../../utils/LanguagePacks/en-us.json";
import Disabled from "../Decorations/Disabled";
import { useAutoEnabler } from "../../utils/customHooks";
import { LOGIN } from "../../utils/routes";
import Handler from "../../classes/Handler";

interface Props {
  useremail: string;
}

export default function VerifyEmail({ useremail }: Props) {
  const [isBtnDisabled, toDisableBtn] = useState(true);
  const count = useAutoEnabler(isBtnDisabled, toDisableBtn, 30);

  // Handle click event
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // Call the email verification API
    await Handler.handleEmailVerification(useremail);
    toDisableBtn(true);
  };

  return (
    <div className="w-[50%] flex flex-col items-center text-center">
      <VerifyEmailLogo />
      <div className="py-4 px-4">{lang.email_verification_text}</div>
      <button
        className="relative btn-primary overflow-hidden px-3 py-2"
        disabled={isBtnDisabled}
        onClick={handleClick}
      >
        {isBtnDisabled ? `${lang.resend_link_wait_text} (${count})` : `${lang.resend_link_text}`}
        {isBtnDisabled && <Disabled />}
      </button>
      <div className="mt-5">
        {lang.already_verified}{" "}
        <a href={LOGIN} className="underline underline-offset-2 text-taxeve-primary-blue">
          Sign In
        </a>
      </div>
    </div>
  );
}
