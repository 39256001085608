import DisableScreenDispatch from "../../interfaces/DisableScreenDispatch.interface";
import { TO_DISABLE_SCREEN } from "../actions/action.types";

const disableScreen = (state = false, action: DisableScreenDispatch) => {
  const { type, payload } = action;
  if (type === TO_DISABLE_SCREEN) return payload;
  return state;
};

export default disableScreen;
