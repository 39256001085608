const Country = [
  { name: "Afghanistan", code: "AF" },
  { name: "Akrotiri", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "AG" },
  { name: "American Samoa", code: "AQ" },
  { name: "Andorra", code: "AN" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AV" },
  { name: "Antarctica", code: "AY" },
  { name: "Antigua & Barbuda", code: "AC" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AA" },
  { name: "Ashmore and Cartier Islands", code: "AT" },
  { name: "Australia", code: "AS" },
  { name: "Austria", code: "AU" },
  { name: "Azerbaijan", code: "AJ" },
  { name: "Bahamas", code: "BF" },
  { name: "Bahrain", code: "BA" },
  { name: "Baker Island", code: "FQ" },
  { name: "Bangladesh", code: "BG" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BO" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BH" },
  { name: "Benin", code: "BN" },
  { name: "Bermuda", code: "BD" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BL" },
  { name: "Bosnia-Herzegovina", code: "BK" },
  { name: "Botswana", code: "BC" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "British Virgin Islands", code: "VI" },
  { name: "Brunei", code: "BX" },
  { name: "Bulgaria", code: "BU" },
  { name: "Burkina Faso", code: "UV" },
  { name: "Burma", code: "BM" },
  { name: "Burundi", code: "BY" },
  { name: "Cambodia", code: "CB" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "CJ" },
  { name: "Central African Republic", code: "CT" },
  { name: "Chad", code: "CD" },
  { name: "Chile", code: "CI" },
  { name: "China", code: "CH" },
  { name: "Christmas Island", code: "KT" },
  { name: "Clipperton Island", code: "IP" },
  { name: "Cocos (Keeling) Islands", code: "CK" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "CN" },
  { name: "Congo (Brazzaville)", code: "CF" },
  { name: "Congo (Kinshasa)", code: "CG" },
  { name: "Cook Islands", code: "CW" },
  { name: "Coral Sea Islands", code: "CR" },
  { name: "Costa Rica", code: "CS" },
  { name: "Cote D'Ivoire (Ivory Coast)", code: "IV" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curacao", code: "UC" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "EZ" },
  { name: "Denmark", code: "DA" },
  { name: "Dhekelia", code: "DX" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DO" },
  { name: "Dominican Republic", code: "DR" },
  { name: "East Timor", code: "TT" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "ES" },
  { name: "Equatorial Guinea", code: "EK" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EN" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Islas Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Federated States of Micronesia", code: "FM" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Polynesia", code: "FP" },
  { name: "French Southern and Antarctic Lands", code: "FS" },
  { name: "Gabon", code: "GB" },
  { name: "The Gambia", code: "GA" },
  { name: "Georgia", code: "GG" },
  { name: "Germany", code: "GM" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GJ" },
  { name: "Guam", code: "GQ" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GK" },
  { name: "Guinea", code: "GV" },
  { name: "Guinea-Bissau", code: "PU" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HA" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See", code: "VT" },
  { name: "Honduras", code: "HO" },
  { name: "Hong Kong", code: "HK" },
  { name: "Howland Island", code: "HQ" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IC" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IZ" },
  { name: "Ireland", code: "EI" },
  { name: "Israel", code: "IS" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Jan Mayen", code: "JN" },
  { name: "Japan", code: "JA" },
  { name: "Jarvis Island", code: "DQ" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kingman Reef", code: "KQ" },
  { name: "Kiribati", code: "KR" },
  { name: "Korea (North)", code: "KN" },
  { name: "Korea (South)", code: "KS" },
  { name: "Kuwait", code: "KU" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LG" },
  { name: "Lebanon", code: "LE" },
  { name: "Lesotho", code: "LT" },
  { name: "Liberia", code: "LI" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LS" },
  { name: "Lithuania", code: "LH" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macau", code: "MC" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "RM" },
  { name: "Martinique", code: "MB" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MN" },
  { name: "Mongolia", code: "MG" },
  { name: "Montenegro", code: "MJ" },
  { name: "Montserrat", code: "MH" },
  { name: "Morocco", code: "MO" },
  { name: "Mozambique", code: "MZ" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NG" },
  { name: "Nigeria", code: "NI" },
  { name: "Niue", code: "NE" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "MU" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PS" },
  { name: "Palestinian Territories", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn Islands", code: "PK" },
  { name: "Poland", code: "PO" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "RQ" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RS" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Barthélemy", code: "TB" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin", code: "RN" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "TP" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SG" },
  { name: "Serbia", code: "RI" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SN" },
  { name: "Sint Maarten", code: "SX" },
  { name: "Slovakia", code: "LO" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "BP" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "SG" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "SP" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "NS" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SW" },
  { name: "Switzerland", code: "SZ" },
  { name: "Syria", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TI" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Togo", code: "TO" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TN" },
  { name: "Trinidad and Tobago", code: "TD" },
  { name: "Tunisia", code: "TS" },
  { name: "Turkey", code: "TU" },
  { name: "Turkmenistan", code: "TX" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "U.S. Virgin Islands", code: "VQ" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UP" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "NH" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "WI" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZI" },
];
export default Country