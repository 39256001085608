import React, { useState } from "react";
import MainImg from "../components/MainImg";
import RegistrationForm from "../components/AuthRelated/RegistrationForm";
import { EMAIL, NAME, PASSWORD, PHONE } from "../utils/constants";
import { UserData } from "../classes/User";
import Formatter from "../classes/Formatter";
import Validator from "../classes/Validator";
import Handler from "../classes/Handler";
import { devLogger } from "../utils/logger.config";
import { SHA256 } from "crypto-js";
import VerifyEmail from "../components/AuthRelated/VerifyEmail";
import { logEvent } from "../googleAnalytics/ga";
import { actions, category, labels } from "../utils/CustomAnalytics";
import ProgressBar from "../components/ProgressBar";

export interface ShowIndicatorOn {
  onName: boolean;
  onEmail: boolean;
  onPhone: boolean;
  onPass: boolean;
  onConfirmPass: boolean;
}

export default function Register() {
  const [userData, setUserData] = useState<UserData>({
    name: "",
    email: "",
    phone: 0,
    password: "",
  });
  const [passConfirmVal, setPassConfirmVal] = useState("");
  const [showPassInstruction, toShowPassInstruction] = useState(false);
  const [showIndicator, toShowIndicator] = useState<ShowIndicatorOn>({
    onName: false,
    onEmail: false,
    onPhone: false,
    onPass: false,
    onConfirmPass: false,
  });
  const [notifyToVerifyEmail, toNotifyToVerifyEmail] = useState(false);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case NAME:
        // Remove any kind of indicator on name
        toShowIndicator({
          ...showIndicator,
          onName: false,
        });

        const namemaxlimit = process.env.REACT_APP_NAME_MAX_LIMIT || "50";

        value.length <= parseInt(namemaxlimit) &&
          setUserData({
            ...userData,
            name: value,
          });
        break;

      case EMAIL:
        // Remove any kind of indicator on email
        toShowIndicator({
          ...showIndicator,
          onEmail: false,
        });

        const emailmaxlimit = process.env.REACT_APP_EMAIL_MAX_LIMIT || "30";

        value.length <= parseInt(emailmaxlimit) &&
          setUserData({
            ...userData,
            email: value,
          });
        break;

      case PHONE:
        // Remove any kind of indicator on phone
        toShowIndicator({
          ...showIndicator,
          onPhone: false,
        });

        const isValidNumber = Validator.validateNumberOnly(Formatter.formatPhone(0, value));
        const isValidLength = Formatter.formatPhone(0, value).length <= 10;

        if (isValidNumber && isValidLength) {
          const phone = Formatter.formatPhone(0, value);

          phone &&
            setUserData({
              ...userData,
              phone: parseInt(phone),
            });
        }
        break;

      case PASSWORD:
        // Remove any kind of indicator on password
        toShowIndicator({
          ...showIndicator,
          onPass: false,
        });

        // Validate the current password
        const isValid = value ? Validator.validatePassword(value) : true;
        !isValid ? toShowPassInstruction(true) : toShowPassInstruction(false);

        // Set the password value
        setUserData({
          ...userData,
          password: value,
        });
        break;

      case `CONFIRM_${PASSWORD}`:
        // Remove any kind of indicator on pass confirm
        toShowIndicator({
          ...showIndicator,
          onConfirmPass: false,
        });

        setPassConfirmVal(value);
        break;

      default:
        break;
    }
  };

  // Handle submit event
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if all the required fields are present

    const nameExists = userData.name.length > 0;
    const emailExists = userData.email.length > 0;
    const phoneExists = userData.phone !== 0;
    const passwordExists = userData.password.length > 0;

    // Check if passwords match
    const match = userData.password === passConfirmVal;

    if (!nameExists && !emailExists && !phoneExists && !passwordExists) {
      toShowIndicator({
        ...showIndicator,
        onName: true,
        onEmail: true,
        onPhone: true,
        onPass: true,
      });
      return;
    } else if (!nameExists) {
      toShowIndicator({
        ...showIndicator,
        onName: true,
      });
      return;
    } else if (!emailExists) {
      toShowIndicator({
        ...showIndicator,
        onEmail: true,
      });
      return;
    } else if (!phoneExists) {
      toShowIndicator({
        ...showIndicator,
        onPhone: true,
      });
      return;
    } else if (!passwordExists) {
      toShowIndicator({
        ...showIndicator,
        onPass: true,
      });
      return;
    }
    if (!match) {
      toShowIndicator({
        ...showIndicator,
        onConfirmPass: true,
      });
      return;
    }

    // Hash the password
    const hash: string = SHA256(process.env.REACT_APP_NONCE + userData.password).toString();

    const updatedUserData: UserData = {
      ...userData,
      password: hash,
    };
    try {
      const res = await Handler.handleRegistration(updatedUserData);
      logEvent(category.auth, actions.registration_success);
      devLogger.log("Response after submitting the registration form");
      devLogger.log(res);
      if (res) {
        // Call the email verification API
        await Handler.handleEmailVerification(userData.email);

        // Notify to verify email
        toNotifyToVerifyEmail(true);
      }
    } catch (error) {
      if (error === 400) {
        logEvent(category.auth, actions.registration_failed, labels.wrong_creadentials);
      } else {
        logEvent(category.auth, actions.registration_failed, labels.server_error);
      }
    }
    // Submit the form
  };

  return (
   <div className=" flex flex-col justify-center items-center mt-5">
      <div className="w-3/5">
        <ProgressBar />
      </div>
      <div className="md:w-1/2 w-full px-2">
        {!notifyToVerifyEmail ? (
          <RegistrationForm
            onChange={handleChange}
            onSubmit={handleSubmit}
            userdata={userData}
            showPassInstruction={showPassInstruction}
            passConfrimVal={passConfirmVal}
            showIndicator={showIndicator}
          />
        ) : (
          <VerifyEmail useremail={userData.email} />
        )}
      </div>
    </div>
  );
}
