import React, { useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import Formatter from "../classes/Formatter";
import ContactUsForm from "../components/InfoRelated/ContactUs/ContactUsForm";
import ContactUsData from "../interfaces/ContactUsData.interface";
import { EMAIL, NAME, PHONE, QUERY } from "../utils/constants";
import Validator from "../classes/Validator";
import Handler from "../classes/Handler";
import { devLogger } from "../utils/logger.config";

export default function ContactUs() {
  const [contactusData, setContactusData] = useState<ContactUsData>({
    name: "",
    email: "",
    query: "",
    phone: 0,
  });
  const [showIndicator, toShowIndicator] = useState({
    onEmail: false,
    onName: false,
    onQuery: false,
  });

  // Handle chane event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: type, value } = e.currentTarget;
    let isValidLength: boolean;

    switch (type) {
      case NAME:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onName: false,
        });

        const namemaxlimit = process.env.REACT_APP_NAME_MAX_LIMIT || "50";

        isValidLength = value.length <= parseInt(namemaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            name: value,
          });
        break;

      case EMAIL:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onEmail: false,
        });

        const emailmaxlimit = process.env.REACT_APP_EMAIL_MAX_LIMIT || "30";

        isValidLength = value.length <= parseInt(emailmaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            email: value,
          });
        break;

      case PHONE:
        const isValid = Validator.validateNumberOnly(Formatter.formatPhone(0, value));
        isValidLength = Formatter.formatPhone(0, value).length <= 10;
        isValid &&
          isValidLength &&
          setContactusData({
            ...contactusData,
            phone: parseInt(Formatter.formatPhone(0, value)),
          });
        break;

      case QUERY:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onQuery: false,
        });

        const querymaxlimit = process.env.REACT_APP_QUERY_MAX_LIMIT || "500";

        isValidLength = value.length <= parseInt(querymaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            query: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle submit event
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email and check if required fields are present
    const isValidEmail = Validator.validateEmail(contactusData.email);
    const namePresent = contactusData.name.length > 0;
    const queryPresent = contactusData.query.length > 0;

    if (!isValidEmail && !namePresent && !queryPresent) {
      toShowIndicator({
        onEmail: true,
        onName: true,
        onQuery: true,
      });
      return;
    } else if (!isValidEmail) {
      toShowIndicator({
        ...showIndicator,
        onEmail: true,
      });
      return;
    } else if (!namePresent) {
      toShowIndicator({
        ...showIndicator,
        onName: true,
      });
      return;
    } else if (!queryPresent) {
      toShowIndicator({
        ...showIndicator,
        onQuery: true,
      });
      return;
    }

    // Handle form submission
    try {
      await Handler.handleContactUsFormSubmission(contactusData);
      // Clear the input fields
      setContactusData({
        name: "",
        email: "",
        query: "",
        phone: 0,
      });
    } catch (errstatus) {
  devLogger.error(errstatus)
    }
  };

  return (
    <div className="page no-page-center">
      <section className="banner">Get in touch</section>
      <section className="flex gap-20 justify-center py-16 px-40">
        <div className="flex flex-col gap-8 justify-center">
          <h2 className="flex items-center gap-3">
            <BsTelephoneFill />
            {Formatter.formatPhone(1, process.env.REACT_APP_PHONE_CONTACT ?? "1234567890")}
          </h2>
          <h2 className="flex items-center gap-3">
            <MdEmail />
            {process.env.REACT_APP_EMAIL_CONTACT}
          </h2>
        </div>
        <ContactUsForm
          onChange={handleChange}
          onSubmit={handleSubmit}
          contactusData={contactusData}
          showIndicator={showIndicator}
        />
      </section>
    </div>
  );
}
