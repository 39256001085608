import { combineReducers } from "@reduxjs/toolkit";
import isUserAuthenticated from "./userAuthRelated/isAuthenticated.reducer";
import theme from "./ThemeRelated/theme.reducer";
import toastMessage from "./toastMessage.reducer";
import disableScreen from "./disableScreen.reducer";

const rootReducer = combineReducers({
  isUserAuthenticated,
  theme,
  toastMessage,
  disableScreen,
});

export default rootReducer;
