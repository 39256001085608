import axiosInstance from "../axios.config";

// Function to handle document upload
export default async function uploadDocument(file: File, taxYear: string, file_type:string) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("taxyear", taxYear);
  formData.append("file_type",file_type);

  try {
    const { data } = await axiosInstance.post("/auth/upload_file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (err) {
    throw err; // Handle error as needed
  }
}
