import React from "react";
import { ERROR, SUCCESS } from "../../utils/constants";
import { RxCross2 } from "react-icons/rx";
import StateSetter from "../../classes/StateSetter";
import ToastMessageObj from "../../interfaces/ToastMessageObj.interface";
import { useDispatch } from "react-redux";

interface Props {
  message: string;
  type: string;
}

export default function Toast({ message, type }: Props) {
  const dispatch = useDispatch();

  const handleClosure = () => {
    const toastObj: ToastMessageObj = {
      message: "",
      type: "",
      toDisplay: false,
    };
    StateSetter.setToastMessage(toastObj, dispatch);
  };

  return (
    <div
      className={`fixed ${
        type === ERROR ? "bg-red-500" : SUCCESS && "bg-green-500"
      } mt-16 w-[100%] text-center py-1 font-bold z-40 text-white`}
    >
      {message}
      <div className="absolute top-0 right-0 h-[100%] px-3 flex items-center">
        <RxCross2 onClick={handleClosure} />
      </div>
    </div>
  );
}
