import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
  type: string;
  buttonText: string; // The text for the OK button
  isCancel?: boolean; // Optional cancel button
  onAgree?: () => void; // Custom action for the OK button
}

function Modal({
  isOpen,
  onClose,
  title,
  content,
  type,
  buttonText,
  isCancel = false,
  onAgree,
}: ModalProps) {
  if (!isOpen) return null;

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, action?: () => void) => {
    e.preventDefault(); // Prevent form submission behavior

    if (action) {
      action(); // If a custom action is passed (e.g., onAgree), call it
    } else {
      onClose(); // Default action is closing the modal
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="dark:bg-black bg-white rounded-lg shadow-lg w-11/12 md:w-2/5">
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        <div className="p-4">
          <p>{content}</p>
        </div>
        <div className="p-4 flex justify-end border-t gap-6">
          <button
            onClick={(e) => handleButtonClick(e, onAgree)} // Call handleButtonClick for OK
            className={`px-4 py-2 ${
              type === "success"
                ? "bg-green-500 hover:bg-green-600"
                : type === "info"
                ? "bg-blue-500 hover:bg-blue-600"
                : "bg-red-500 hover:bg-red-600"
            } text-white rounded `}
          >
            {buttonText}
          </button>
          {isCancel && (
            <button
              onClick={(e) => handleButtonClick(e)} // Call handleButtonClick for Cancel
              className={`px-4 py-2 ${
                type === "success"
                  ? "bg-green-500 hover:bg-green-600"
                  : type === "info"
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-red-500 hover:bg-red-600"
              } text-white rounded`}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
