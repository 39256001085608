import React from 'react'

function SettingContent() {
  return (
    <div>
        <h1>Setting content</h1>
    </div>
  )
}

export default SettingContent