import axiosInstance from "../axios.config";
import { devLogger, prodLogger } from "../logger.config";

export default async function otpForEmailUpdate({
  password,
  new_email,
}: {
  password: string;
  new_email: string;
}) {
  try {
    devLogger.log("Calling the API to send a verification code to the new email address...");
    const { data } = await axiosInstance.post("/send_vc_for_email_update", {
      password,
      new_email,
    });
    devLogger.log("OTP sent, response received from server!");
    return data;
  } catch (err) {
    prodLogger.error("Error in response!");
    throw err;
  }
}
