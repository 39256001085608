import { Dispatch } from "@reduxjs/toolkit";
import { ERROR } from "../utils/constants";
import StateSetter from "./StateSetter";
import FormDataProcessingArgs from "../interfaces/FormDataProcessingArgs.interface";
import Validator from "./Validator";
import lang from "../utils/LanguagePacks/en-us.json";
import { SHA256 } from "crypto-js";
import Formatter from "./Formatter";

export default class Middleware {
  public static dispatch: Dispatch<any> | null = null;

  public static processFormData(
    { requiredValues, validate, evt }: FormDataProcessingArgs,
    callback: (
      err: any,
      validatedFields?: {
        email?: string;
        hashedPass?: string;
        phone?: string;
      }
    ) => void
  ) {
    try {
      evt.preventDefault();
      // Start validation for required fields
      const valArrLength = requiredValues.length;
      let i = 0;
      let isValid = true;
      while (i < valArrLength) {
        if (!requiredValues[i]) {
          isValid = false;
          break;
        }
        i++;
      }
      if (!isValid) {
        // Handle this
        this.dispatch
          ? StateSetter.setToastMessage(
              {
                message: "Please fill all the required fields marked with '*' !",
                type: ERROR,
                toDisplay: true,
              },
              this.dispatch
            )
          : alert("Dispatch is not initialized for middleware!");
        return;
      }

      // Check if other validation required
      if (validate?.email) {
        const isValidEmail = Validator.validateEmail(validate.email);
        if (!isValidEmail) {
          this.dispatch
            ? StateSetter.setToastMessage(
                {
                  message: lang.email_required,
                  type: ERROR,
                  toDisplay: true,
                },
                this.dispatch
              )
            : alert("Dispatch is not initialized for middleware!");
          return;
        }
      }
      if (validate?.password) {
        const isValidPass = Validator.validatePassword(validate.password);
        if (!isValidPass) {
          this.dispatch
            ? StateSetter.setToastMessage(
                {
                  message: lang.pass_required,
                  type: ERROR,
                  toDisplay: true,
                },
                this.dispatch
              )
            : alert("Dispatch is not initialized for middleware!");
          return;
        }
      }

      if (validate?.confirmPassword) {
        const isMatch = validate.password === validate.confirmPassword;
        if (!isMatch) {
          this.dispatch
            ? StateSetter.setToastMessage(
                {
                  message: lang.pass_dont_match,
                  type: ERROR,
                  toDisplay: true,
                },
                this.dispatch
              )
            : alert("Dispatch is not initialized for middleware!");
          return;
        }
      }

      if (validate?.phone) {
        const isValidPhone = Validator.validatePassword(validate.phone);
        if (!isValidPhone) {
          this.dispatch
            ? StateSetter.setToastMessage(
                {
                  message: lang.phone_required,
                  type: ERROR,
                  toDisplay: true,
                },
                this.dispatch
              )
            : alert("Dispatch is not initialized for middleware!");
          return;
        }
      }

      // Process the validated data
      const hashedPass: string | undefined =
        validate?.password && SHA256(process.env.REACT_APP_NONCE + validate.password).toString();

      const unformattedPhone: string | undefined =
        validate?.phone && Formatter.formatPhone(0, validate.phone);

      callback(null, {
        email: validate?.email,
        phone: unformattedPhone,
        hashedPass: hashedPass,
      });
    } catch (err) {
      callback(err);
    }
  }
}
