import React from "react";

interface Props {
  message: string;
}

export default function Indicator({ message }: Props) {
  return (
    <div className="text-red-600">
      <p>* {message}</p>
    </div>
  );
}
