import React from "react";
import Name from "../../AuthRelated/Name";
import Email from "../../AuthRelated/Email";
import Phone from "../../AuthRelated/Phone";
import { QUERY } from "../../../utils/constants";
import ContactUsData from "../../../interfaces/ContactUsData.interface";
import Indicator from "../../Indicator";
import lang from "../../../utils/LanguagePacks/en-us.json";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  contactusData: ContactUsData;
  showIndicator: { onEmail: boolean; onName: boolean; onQuery: boolean };
}

export default function ContactUsForm({ onChange, onSubmit, contactusData, showIndicator }: Props) {
  return (
    <form className="w-[50%]" onSubmit={onSubmit}>
      <h1 className="text-center">Write to us</h1>
      <div className="mt-4 flex flex-wrap gap-4">
        <div className="w-[48%]">
          <Name onChange={onChange} name={contactusData.name} placeholder="Full name *" />
          {showIndicator.onName && <Indicator message={lang.required} />}
        </div>
        <div className="w-[48%]">
          <Email onChange={onChange} email={contactusData.email} />
          {showIndicator.onEmail && <Indicator message={lang.email_required} />}
        </div>
        <div className="w-[48%]">
          <Phone onChange={onChange} phone={contactusData.phone ?? 0} placeholder="Phone" />
        </div>
      </div>
      <div className="mt-4">
        <textarea
          name="query"
          id={QUERY}
          className="resize-none w-[100%] min-h-[10rem] text-base"
          placeholder="Write your query here *"
          onChange={onChange}
          value={contactusData.query}
        />
        {showIndicator.onQuery && <Indicator message={lang.required} />}
      </div>
      <div className="text-center">
        <button className="btn-primary py-2 px-4 mt-4" type="submit">
          Send
        </button>
      </div>
    </form>
  );
}
