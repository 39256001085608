import axiosInstance from "../axios.config";

export default async function sendVerificationCode(email: string) {
  try {
    const { data } = await axiosInstance.post("/send_verification_code", { email: email });
    return data;
  } catch (err) {
    throw err;
  }
}
