import React, { useState } from "react";
import Middleware from "../../../classes/Middleware";
import { prodLogger } from "../../../utils/logger.config";
import { CONFIRM_PASSWORD, NEW_PASSWORD, PASSWORD } from "../../../utils/constants";
import Password from "../../AuthRelated/Password";
import Handler from "../../../classes/Handler";
import { SHA256 } from "crypto-js";

export default function PasswordUpdate() {
  const [requiredfields, setRequiredfields] = useState({
    oldpassword: "",
    newpassword: "",
    confirmNewPassword: "",
  });
  // const dispatch = useDispatch();

  // Handle change in input fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case PASSWORD:
        setRequiredfields({
          ...requiredfields,
          oldpassword: value,
        });
        break;

      case NEW_PASSWORD:
        setRequiredfields({
          ...requiredfields,
          newpassword: value,
        });
        break;

      case CONFIRM_PASSWORD:
        setRequiredfields({
          ...requiredfields,
          confirmNewPassword: value,
        });
        break;

      default:
        break;
    }
  };

  const nonEmptyFormSubmitCb = async (
    err: any,
    validatedFields?: {
      email?: string;
      hashedPass?: string;
      phone?: string;
    }
  ) => {
    if (err) {
      prodLogger.error(err);
      return;
    }
    if (validatedFields) {
      // Call the password update API handler
      Handler.handlePasswordUpdate({
        old_password: SHA256(process.env.REACT_APP_NONCE + requiredfields.oldpassword).toString(),
        new_password: validatedFields.hashedPass ?? "",
      })
        .then(() => {
          setRequiredfields({
            oldpassword: "",
            newpassword: "",
            confirmNewPassword: "",
          });
        })
        .catch((errstatus) => {
          // Error will ne handled in the handler itself
        });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        Middleware.processFormData(
          {
            requiredValues: [
              requiredfields.oldpassword,
              requiredfields.newpassword,
              requiredfields.confirmNewPassword,
            ],
            evt: e,
            validate: {
              password: requiredfields.newpassword,
              confirmPassword: requiredfields.confirmNewPassword,
            },
          },
          nonEmptyFormSubmitCb
        );
      }}
    >
      <div className="flex gap-6">
        <div className="w-[50%]">
          <div className="flex flex-col gap-2 justify-center py-6 px-6">
            <label htmlFor={PASSWORD} className="font-bold">
              Current Password
            </label>
            <em>
              <p className="text-[1rem]">Type your current password below</p>
            </em>
            <Password
              id={PASSWORD}
              onChange={handleChange}
              value={requiredfields.oldpassword}
              placeholder="Current Password *"
            />
          </div>

          <div className="flex flex-col gap-2 justify-center py-6 px-6">
            <label htmlFor={NEW_PASSWORD} className="font-bold">
              Password
            </label>
            <em>
              <p className="text-[1rem]">Type your new password below</p>
            </em>
            <Password
              id={NEW_PASSWORD}
              onChange={handleChange}
              value={requiredfields.newpassword}
              placeholder="New Password *"
            />
          </div>

          <div className="flex flex-col gap-2 justify-center py-6 px-6">
            <label htmlFor={CONFIRM_PASSWORD} className="font-bold">
              Password
            </label>
            <em>
              <p className="text-[1rem]">Confirm your password below</p>
            </em>
            <Password
              id={CONFIRM_PASSWORD}
              onChange={handleChange}
              value={requiredfields.confirmNewPassword}
              placeholder="Confirm Password *"
            />
          </div>
        </div>
      </div>

      <div className="px-6 py-6">
        <button
          type="submit"
          className="py-[0.85rem] px-4 rounded-xl bg-student-tax-secondary-theme-color hover:bg-white-color text-white hover:text-student-tax-secondary-theme-color font-semibold font-sans"
        >
          Change password
        </button>
      </div>
    </form>
  );
}
