export default class Formatter {
  static formatPhone(formatType: number, phone: string): string {
    /*
            Format type:
                0 - original number format
                1 - formatted string format
        */
    switch (formatType) {
      case 0:
        const original = phone
          ? phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")
          : "0";
        return original;

      case 1:
        let formatted = phone;
        // Start the formatting
        if (phone === "0") return "";
        if (phone.length > 3 && phone.length <= 6) {
          formatted = "(" + phone.substring(0, 3) + ") " + phone.substring(3);
        } else if (phone.length > 6) {
          formatted =
            "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6);
        }
        return formatted;

      default:
        return "";
    }
  }
}
