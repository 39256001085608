import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ProgressBar: React.FC = () => {
  const location = useLocation();

  // State for smooth animation of progress
  const [progress, setProgress] = useState(0);
  const [previousProgress, setPreviousProgress] = useState(0); // Track the previous progress

  // Determine progress based on the current route
  const getProgress = () => {
    switch (location.pathname) {
      case "/register":
      case "/login":
        return { step: 1, total: 3, percentage: 33 };
      case "/home":
        return { step: 2, total: 3, percentage: 66 };
      case "/cart":
        return { step: 3, total: 3, percentage: 100 };
      default:
        return { step: 0, total: 3, percentage: 0 };
    }
  };

  const { step, total, percentage } = getProgress();

  // Animate progress bar when the route changes
  useEffect(() => {
    // Update the previous progress
    setPreviousProgress(progress);

    // Animate from the previous progress to the new percentage
    const timeout = setTimeout(() => setProgress(percentage), 200); // Smooth animation
    return () => clearTimeout(timeout);
  }, [percentage]);

  return (
    <div className="w-full h-6 bg-gray-100 border border-green-700 rounded-full relative ">
      {/* Progress Fill */}
      <div
        className="h-full transition-all duration-1000 ease-out"
        style={{
          width: `${progress}%`,
          background: `linear-gradient(90deg, #5278530D 5%, #527853 100%)`, // Gradient for progress
        }}
      ></div>

      {/* Circle with Step */}
      <div
        className="absolute top-1/2 -translate-y-1/2 transform w-8 h-8 bg-student-tax-primary-theme-color text-white flex items-center justify-center text-sm font-bold rounded-full shadow-md transition-all duration-1000 ease-out"
        style={{
          left: `${progress}%`,
          transform: "translate(-50%, -50%)",
        }}
      >
        {`${step}/${total}`}
      </div>
    </div>
  );
};

export default ProgressBar;
