import axiosInstance from "../axios.config";


export default async function downloadAdminDocument(fileId: string): Promise<Blob> {
  try {
    const response = await axiosInstance.get(`/admin/auth/download_file/${fileId}`, {
      responseType: "blob", // Specify that we expect a blob response
      headers: {
        Accept: "application/pdf, application/octet-stream", // Adjust based on the expected file types
      },
    });

    return response.data; // Return the Blob data directly
  } catch (err) {
    throw err; // Propagate the error for further handling
  }
}
