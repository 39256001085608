import React from "react";

interface UserDetailProps {
  label: string;
  value: string | number; // Handle both string and numeric values
  id?: string; // Optional prop for ID
  onClick?: (id: string) => void;
}

const UserDetail: React.FC<UserDetailProps> = ({ label, value, id, onClick }) => {
  const handleClick = () => {
    if (onClick && id) {
      onClick(id); 
    }
  };
  return (
    <div>
      <span className="font-bold text-lg text-light-black dark:text-gray-300">{label}:</span>

      <div className="cursor-pointer relative w-full flex justify-between items-center my-2 bg-body-color dark:bg-gray-800 p-2 rounded-lg shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)]">
        <div onClick={id ? handleClick : undefined} className="cursor-pointer">
          <span
            className={`${
              id ? "text-blue-500 underline" : "text-light-black dark:text-white "
            } text-md font-semibold`}
          >
            {value ? value : "No"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
