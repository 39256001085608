// import React, { useState, useEffect } from "react";
// import { BsFillSunFill, BsFillMoonStarsFill } from "react-icons/bs";
// import { devLogger } from "../utils/logger.config";
// import StateSetter from "../classes/StateSetter";
// import { DARK, LIGHT, LS } from "../utils/constants";
// import { Dispatch } from "@reduxjs/toolkit";
// import ThemeDispatch from "../interfaces/ThemeDispatch.interface";
// import { useDispatch } from "react-redux";
// import Cache from "../classes/Cache";

// const lsCache = new Cache(LS);

// export default function ThemeToggler() {
//   const [isLightTheme, setIsLightTheme] = useState(true);
//   const dispatch: Dispatch<ThemeDispatch> = useDispatch();

//   // Check for cached theme on reload for persistence
//   useEffect(() => {
//     const isLightTheme = lsCache.get("theme") === LIGHT;
//     setIsLightTheme(isLightTheme);
//   }, []);

//   // Handle change event
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setIsLightTheme(!isLightTheme);
//     !isLightTheme ? StateSetter.setTheme(LIGHT, dispatch) : StateSetter.setTheme(DARK, dispatch);
//   };

//   useEffect(() => {
//     devLogger.log(`Is light theme: ${isLightTheme}`);
//   }, [isLightTheme, dispatch]);

//   return (
//     <div className="flex items-center gap-2">
//       {/* <!-- Rounded switch --> */}
//       <label className="switch">
//         <input type="checkbox" onChange={handleChange} checked={!isLightTheme} />
//         <span className="slider round"></span>
//       </label>
//       {isLightTheme ? (
//         <BsFillSunFill  className="text-xl text-white cursor-pointer" />
//       ) : (
//         <BsFillMoonStarsFill className="text-xl" />
//       )}
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { BsFillSunFill, BsFillMoonStarsFill } from "react-icons/bs";
import { devLogger } from "../utils/logger.config";
import StateSetter from "../classes/StateSetter";
import { DARK, LIGHT, LS } from "../utils/constants";
import { Dispatch } from "@reduxjs/toolkit";
import ThemeDispatch from "../interfaces/ThemeDispatch.interface";
import { useDispatch } from "react-redux";
import Cache from "../classes/Cache";

const lsCache = new Cache(LS);

export default function ThemeToggler() {
  const [isLightTheme, setIsLightTheme] = useState(true);
  const dispatch: Dispatch<ThemeDispatch> = useDispatch();

  // Check for cached theme on reload for persistence
  useEffect(() => {
    const isLightTheme = lsCache.get("theme") === LIGHT;
    setIsLightTheme(isLightTheme);
  }, []);

  // Handle theme change
  const toggleTheme = () => {
    const newTheme = !isLightTheme;
    setIsLightTheme(newTheme);
    newTheme ? StateSetter.setTheme(LIGHT, dispatch) : StateSetter.setTheme(DARK, dispatch);
  };

  useEffect(() => {
    devLogger.log(`Is light theme: ${isLightTheme}`);
  }, [isLightTheme, dispatch]);

  return (
    <div className="flex items-center">
      {/* Switch */}
      {/* <label className="switch">
        <input type="checkbox" onChange={toggleTheme} checked={!isLightTheme} />
        <span className="slider round"></span>
      </label> */}
      {/* Icons */}
      {isLightTheme ? (
        <BsFillSunFill
          className="text-xl text-white cursor-pointer"
          onClick={toggleTheme} // Add click handler
        />
      ) : (
        <BsFillMoonStarsFill
          className="text-xl cursor-pointer"
          onClick={toggleTheme} // Add click handler
        />
      )}
    </div>
  );
}
