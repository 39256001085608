import { LS, SS } from "../utils/constants";
import { devLogger } from "../utils/logger.config";

export default class Cache {
  cacheType: string = "";

  // Cache types - LS: LocalStorage, SS: SessionStorage
  constructor(cachetype: string) {
    this.cacheType = cachetype;
  }

  save(storename: string, storevalue: string) {
    if (this.cacheType === LS) {
      localStorage.setItem(storename, storevalue);
      return;
    } else if (this.cacheType === SS) {
      sessionStorage.setItem(storename, storevalue);
      return;
    }
    return;
  }

  get(storename: string) {
    if (this.cacheType === LS) {
      const item = localStorage.getItem(storename);
      return item;
    } else if (this.cacheType === SS) {
      const item = sessionStorage.getItem(storename);
      return item;
    }
    return null;
  }

  public remove(storename: string): void {
    if (this.cacheType === LS) {
      localStorage.removeItem(storename);
      devLogger.log(`${storename} removed from local storage!`);
    } else if (this.cacheType === SS) {
      sessionStorage.removeItem(storename);
      devLogger.log(`${storename} removed from session storage!`);
    }
  }
}
