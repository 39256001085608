import React from "react";
import { useShowPassword } from "../../utils/customHooks";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

interface Props {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
}

export default function Password({ id, onChange, value, placeholder }: Props) {
  const [showPassword, toShowPassword] = useShowPassword(false);

  return (
    <div className="relative">
      <input
        id={id}
        type={showPassword ? "text" : "password"}
        className="border border-gray-300 w-[100%] shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {showPassword ? (
        <AiFillEyeInvisible
          className="input-icon"
          onClick={() => {
            toShowPassword(false);
          }}
        />
      ) : (
        <AiFillEye
          className="input-icon"
          onClick={() => {
            toShowPassword(true);
          }}
        />
      )}
    </div>
  );
}
