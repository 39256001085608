import React from "react";
import { PASSWORD, PASSWORD_RESET_FORM, VERIFICATION_CODE } from "../../../utils/constants";
import PasswordResetData from "../../../interfaces/PasswordResetData.interface";
import { BiSolidShield, BiUser } from "react-icons/bi";
import Password from "../Password";
import Indicator from "../../Indicator";
import lang from "../../../utils/LanguagePacks/en-us.json";
import Email from "../Email";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  passResetData: PasswordResetData;
  confirmPassVal: string;
  showIndicator: { onPassword: boolean; onConfirmPassword: boolean; onVerificationCode: boolean };
}

export default function PasswordResetForm({
  onChange,
  onSubmit,
  passResetData,
  showIndicator,
  confirmPassVal,
}: Props) {
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-7rem)] relative mt-7">
      <div className="w-full max-w-md  shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] rounded-2xl p-6 sm:p-8 border border-gray-200 transform hover:scale-105 transition duration-300 ">
        <form id={PASSWORD_RESET_FORM} className="space-y-4 mt-16" onSubmit={onSubmit}>
          <div>
            <Email onChange={onChange} email={passResetData.email} disabled={true} />
          </div>

          <div className="relative">
            <Password
              id={PASSWORD}
              onChange={onChange}
              value={passResetData.new_password}
              placeholder="New Password *"
            />
            {showIndicator?.onPassword && <Indicator message={lang.pass_required} />}
          </div>
          <div className="relative">
            <Password
              id={`CONFIRM_${PASSWORD}`}
              onChange={onChange}
              value={confirmPassVal}
              placeholder="Confirm Password *"
            />
            {showIndicator?.onConfirmPassword && <Indicator message={lang.pass_dont_match} />}
          </div>
          <div className="relative">
            <input
              id={VERIFICATION_CODE}
              type="text"
              className="border border-gray-300 w-[100%] shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Verification code *"
              onChange={onChange}
              value={passResetData.verification_code}
            />
            <BiSolidShield className="input-icon" />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
      <div className="h-32 w-32 md:h-40 md:w-40 bg-navy-blue rounded-full absolute top-4 flex justify-center items-center">
        <BiUser className="text-white-color text-8xl" />
      </div>
    </div>
  );
}
