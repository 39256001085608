import React from "react";

function AppSection() {
  return (
    <div className="px-52 py-14 flex flex-col items-center bg-why-to-choose-background ">
      <div className="flex flex-col items-center gap-2">
        <img src="/Assets/Correct.svg" alt="correct" />
        <span className="text-text-gray text-xl font-sans">
          File faster and easier with the free Student Tax app
        </span>
        <div className="flex flex-row gap-2">
          <img src="/Assets/Apple Store.svg" alt="correct" />
          <img src="/Assets/play Store.svg" alt="correct" />
        </div>
      </div>
    </div>
  );
}

export default AppSection;
