import React, { useState, useEffect } from "react";
import { BiCaretDown } from "react-icons/bi";

interface YearDropdownProps {
  currentYear: number;
  onYearChange: (year: number) => void; // Callback to pass the selected year
  selectedYear?: number; // Optional prop for the selected year
  disbled?: boolean;
}

function YearDropdown({
  currentYear,
  onYearChange,
  selectedYear,
  disbled = false,
}: YearDropdownProps) {
  // Default selected year to currentYear - 1 if not provided
  const defaultSelectedYear = selectedYear !== undefined ? selectedYear : currentYear - 1;

  const [localSelectedYear, setLocalSelectedYear] = useState(defaultSelectedYear);

  // Update local state when selectedYear prop changes
  useEffect(() => {
    setLocalSelectedYear(selectedYear !== undefined ? selectedYear : currentYear - 1);
  }, [selectedYear, currentYear]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = Number(event.target.value);
    setLocalSelectedYear(newYear);
    onYearChange(newYear); // Call the callback with the new year
  };

  return (
    <div
      className={`relative inline-block md:w-64 w-full ${
        disbled && "bg-slate-300 dark:bg-slate-600"
      }`}
    >
      <select
        value={localSelectedYear}
        onChange={handleChange}
        className="block w-full px-4 py-2 border dark:bg-slate-600 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] cursor-pointer"
        disabled={disbled}
      >
        <option value={currentYear - 1}>{currentYear - 1}</option>
        <option value={currentYear - 2}>{currentYear - 2}</option>
        <option value={currentYear - 3}>{currentYear - 3}</option>
      </select>
      <div className="absolute top-0 right-0 flex items-center h-full px-2 pointer-events-none">
        <BiCaretDown />
      </div>
    </div>
  );
}

export default YearDropdown;
