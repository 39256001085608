import axiosInstance from "../axios.config";
import { devLogger, prodLogger } from "../logger.config";

export default async function updatePhone({
  password,
  phone_no,
}: {
  password: string;
  phone_no: number;
}) {
  try {
    devLogger.log("Calling the phone number update API...");
    const { data } = await axiosInstance.post("/update_phone", { password, phone_no });
    devLogger.log("Success response received from server!");

    return data;
  } catch (err) {
    prodLogger.error("Error in response!");
    throw err;
  }
}
