// Auth related
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";

// Theme related
export const SET_THEME = "SET_THEME";

// Toast message related
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";

// Screen disabler related
export const TO_DISABLE_SCREEN = "TO_DISABLE_SCREEN";

// user deetails 
export const Set_User_Details ="Set_User_Details";

