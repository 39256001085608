import React from 'react'

function DashboardContent() {
  return (
    <div>
        <h1>Dashboard Content</h1>
        <p>welcome in dashboard </p>
    </div>
  )
}

export default DashboardContent