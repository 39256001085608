import { devLogger } from "../utils/logger.config";

export default class Cookie {
  private static cookiePos(name: string): number {
    const startIdx = document.cookie.indexOf(name);
    return startIdx;
  }

  static setCookie(cookiename: string, value: string) {
    document.cookie = `${cookiename}=${value}; Path=/; Secure; SameSite=None`;
  }

  static retrieveCookie(name: string): string | null {
    // Rectrive a cookie
    const startIdx = this.cookiePos(name); // Position of the cookie in string
    const cookieSplit =
      startIdx >= 0 ? document.cookie.substring(startIdx, document.cookie.length + 1) : "";

    const cookie = cookieSplit.split("; ")[0]; // The first element is the desired cookie because the cookie string was split from the start idx of the desired cookie
    return cookie ?? null;
  }

  static removeCookie(name: string) {
    devLogger.log(`Name of cookie to remove ---> ${name}`);
    // Remove the cookie
    const cookie = this.retrieveCookie(name);
    devLogger.log(`Cookie to remove ---> ${cookie}`);
    if (cookie) {
      const cookiename = cookie.split("=")[0];
      document.cookie = `${cookiename}=; expires=${new Date().toUTCString()}; path=/`;
    }
  }
}
