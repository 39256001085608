import axiosInstance from "../axios.config";

// Function to handle document upload
export default async function downloadDocument(fileId: string): Promise<Blob> {
  try {
    const response = await axiosInstance.get(`/auth/download_file/${fileId}`, {
      responseType: "blob", // Specify that we expect a blob response
      headers: {
        Accept: "application/pdf, application/octet-stream", // Adjust based on the expected file types
      },
    });

    return response.data; // Return the Blob data directly
  } catch (err) {
    throw err; // Propagate the error for further handling
  }
}
