import React from "react";

export default function PasswordSetupInstruction() {
  return (
    <div className="absolute mt-1 rounded-lg w-[100%] z-10 px-4 py-4 bg-[#EC625F]">
      <h5 className="font-bold">Password must contain:</h5>
      <ul className="list-disc list-inside font-semibold">
        <li>At least 9 characters</li>
        <li>One uppercase letter</li>
        <li>One smallcase letter</li>
        <li>One integer</li>
        <li>One special character</li>
      </ul>
    </div>
  );
}
