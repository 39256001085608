import React from "react";

export default function VerifyEmailLogo() {
  // TODO: Need to change this image
  return (
    <div className="w-[20%]">
      <img
        src="https://www.freepnglogos.com/uploads/email-png/business-email-mail-icon-35.png"
        alt="verify email"
      />
    </div>
  );
}
