import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export default async function emailVerification(email: string) {
  try {
    const { data } = await axiosInstance.post("/verify_email", { email: email });
    devLogger.log(`Response from server after calling the verify email API`);
    devLogger.log(data);
    return data;
  } catch (err) {
    throw err;
  }
}
