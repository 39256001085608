import PasswordResetData from "../../interfaces/PasswordResetData.interface";
import axiosInstance from "../axios.config";

export default async function resetPassword(passResetData: PasswordResetData) {
  try {
    const { data } = await axiosInstance.post("/reset_password", passResetData);
    return data;
  } catch (err) {
    throw err;
  }
}
