// src/api/getUserData.ts
import axiosInstance from "../axios.config";

export default async function getUserData(year: number) {
  try {
    const { data } = await axiosInstance.get(`admin/auth/get_all_users`, {
      params: {
        year: year, 
      },
    });
    return data;
  } catch (err) {
    throw err; 
  }
}