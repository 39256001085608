import React from "react";
import { PASSWORD } from "../../utils/constants";
import Indicator from "../Indicator";
import Password from "./Password";
import lang from "../../utils/LanguagePacks/en-us.json";
import { Link } from "react-router-dom";
import { ADMIN_FORGOT_PASSWORD, FORGOT_PASSWORD, REGISTER } from "../../utils/routes";
import Email from "./Email";
import { BiUser } from "react-icons/bi";

interface Props {
  email: string;
  password: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  showIndicator: { onEmail: boolean; onPass: boolean };
  onAdminPortal?: boolean;
}

export default function LoginForm({
  email,
  password,
  onChange,
  showIndicator,
  onSubmit,
  onAdminPortal,
}: Props) {
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-9rem)] relative mt-7">
      <div className="w-full max-w-md  shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] rounded-2xl p-6 sm:p-8 border border-gray-200 transform hover:scale-105 transition duration-300 ">
        <form className="space-y-4 mt-16" onSubmit={onSubmit}>
          <div>
            <Email onChange={onChange} email={email} />
            {showIndicator?.onEmail && <Indicator message={lang.email_required} />}
          </div>

          <div className="relative">
            <Password id={PASSWORD} onChange={onChange} value={password} placeholder="Password *" />
            {showIndicator?.onPass && <Indicator message={lang.pass_required} />}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Sign in
            </button>
          </div>
        </form>
        <div className="text-center py-4">
          <Link
            to={!onAdminPortal ? FORGOT_PASSWORD : ADMIN_FORGOT_PASSWORD}
            className="link text-student-tax-secondary-theme-color font-semibold"
          >
            Forgot password?
          </Link>
        </div>
        {!onAdminPortal && (
          <>
            <div className="py-6 text-center">
              {lang.not_registered}{" "}
              <Link
                to={REGISTER}
                className="link text-student-tax-secondary-theme-color font-semibold"
              >
                Sign up
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="h-32 w-32 md:h-40 md:w-40 bg-navy-blue rounded-full absolute top-0 flex justify-center items-center">
        <BiUser className="text-white-color text-8xl" />
      </div>
    </div>
  );
}
