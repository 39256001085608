import axiosInstance from "../axios.config";
import { devLogger, prodLogger } from "../logger.config";

export default async function updateUsername(newname: string) {
  try {
    devLogger.log("Calling the username update API...");
    const { data } = await axiosInstance.post("/update_username", {
      new_name: newname,
    });
    devLogger.log("Response received!");
    return data;
  } catch (err) {
    prodLogger.error("Error response!");
    throw err;
  }
}
