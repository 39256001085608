// const DocumentList = [
//   { label: "W2Form", value: "w2" },
//   { label: "Form1099", value: "1099" },
//   { label: "AnyOtherIncomeForm", value: "other_income" },
//   { label: "Tuition Statement", value: "1098t" },
//   { label: "Passport", value: "passport" },
//   { label: "USEntryExitDates", value: "us_entry_exit" },
//   { label: "AllIncomeForm", value: "other_income" },
//   { label: "SSN", value: "tin" },
//   { label: "visa", value: "immi_status" },
// ];
const DocumentList = [
  { name: "W2Form", code: "w2" },
  { name: "Form1099", code: "1099" },
  { name: "AnyOtherIncomeForm", code: "other_income" },
  { name: "Tuition Statement", code: "1098t" },
  { name: "Passport", code: "passport" },
  { name: "USEntryExitDates", code: "us_entry_exit" },
  { name: "AllIncomeForm", code: "other_income" },
  { name: "SSN", code: "tin" },
  { name: "visa", code: "immi_status" },
];

export default DocumentList;
