import { UserData } from "../../classes/User";
import axiosInstance from "../axios.config";

export default async function register(userdata: UserData) {
  try {
    const { data } = await axiosInstance.post("/register", userdata);
    return data;
  } catch (err) {
    throw err;
  }
}
