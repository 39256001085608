import React, { useEffect, useState } from "react";
import StudentTaxLogo from "./StudentTaxLogo";
import { useLocation } from "react-router-dom";
import { devLogger } from "../utils/logger.config";
import {
  ADMIN_DASHBOARD,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_LOGIN,
  CART,
  CONTACT_US,
  FORGOT_PASSWORD,
  HISTORY,
  HOME,
  // LANDING_PAGE,
  LOGIN,
  PROFILE_SETTINGS,
  REGISTER,
} from "../utils/routes";
import ContactCred from "./ContactCred";
import ThemeToggler from "./ThemeToggler";
import LoginBtn from "./Decorations/LoginBtn";
import RegisterBtn from "./Decorations/RegisterBtn";
import ContactUsLink from "./InfoRelated/ContactUs/ContactUsLink";
import ProfilePanel from "./ProfileRelated/ProfilePanel";
import Cache from "../classes/Cache";
import { SS } from "../utils/constants";
import AdminNavbar from "./AdminReated/AdminNavbar";
import { MdMenu } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import NavbarText from "./navrelated/NavbarText";

// const pathWithContactOnNav = [LOGIN, REGISTER, FORGOT_PASSWORD];
// const pathWithDefaultItemsOnNav = [LANDING_PAGE, CONTACT_US];
// const postLoginPages = [HOME, PROFILE_SETTINGS];

const getPathBasedComponent = (currentPath: string, username?: string, email?: string) => {
  switch (currentPath) {
    case LOGIN:
    case REGISTER:
    case FORGOT_PASSWORD:
      return <ContactCred />;

    case HOME:
    case PROFILE_SETTINGS:
    case HISTORY:
    case CART:
      return (
        <div className="flex">
          <ProfilePanel username={username ?? ""} email={email ?? ""} />
        </div>
      );

    // Admin routes
    case ADMIN_LOGIN:
    case ADMIN_DASHBOARD:
    case ADMIN_FORGOT_PASSWORD:
      return <AdminNavbar />;

    default:
      return (
        <div className="flex items-center gap-4">
          {/* <ContactUsLink selected={currentPath === CONTACT_US} /> */}
          <LoginBtn />
          <RegisterBtn />
        </div>
      );
  }
};

export default function Navbar() {
  const [userDetail, setUserDetail] = useState({
    username: "",
    email: "",
  });
  const location = useLocation();
  const currentpath = location.pathname;

  devLogger.log(`Current Location ---> ${currentpath}`);

  // Get the username and email from cache
  useEffect(() => {
    const sscache = new Cache(SS);
    const userDetailStr: string | null = sscache.get("user_detail");
    if (userDetailStr) {
      const userDetailParsed: { username: string; email: string; phone: number } =
        JSON.parse(userDetailStr);
      setUserDetail({
        username: userDetailParsed.username,
        email: userDetailParsed.email,
      });
    }
  }, [currentpath]);
  console.log(currentpath, "..");
  return (
    <div className="flex justify-between items-center bg-student-tax-primary-theme-color shadow-xl px-10 py-4 w-full navbar">
      {/* Left Section: Logo */}
      <div className="flex-1">
        <StudentTaxLogo onAdmin={currentpath.includes("admin-portal")} />
      </div>
      {/* Middle Section: Centered Navbar Links */}
      {(currentpath === "/login" ||
        currentpath === REGISTER ||
        currentpath === FORGOT_PASSWORD) && (
        <div className="flex-1 flex justify-center items-center gap-10">
          <NavbarText value="Home" />
          <NavbarText value="About Us" />
          <NavbarText value="Contact Us" />
          <ThemeToggler />
        </div>
      )}
      {/* Right Section: Profile or Login/Register */}
      <div className="flex-1 flex justify-end items-center">
        {getPathBasedComponent(currentpath, userDetail.username, userDetail.email)}
      </div>
    </div>
  );
}
