import React from "react";
import CartPage from "../components/cartRelated/CartPage";
import { useLocation } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";

function Cart() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get("q") || undefined; // If missing, q will be undefined
  const ty = queryParams.get("ty") || undefined; // If missing, ty will be undefined
  return (
    <>
      <div className="w-full flex justify-center items-center flex-col ">
        <div className="w-3/5 my-4">
          <ProgressBar />
        </div>
        <h1>Cart</h1>
      </div>
      <div>
        <CartPage q={q} ty={ty} />
      </div>
    </>
  );
}

export default Cart;
