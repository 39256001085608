import axiosInstance from "../axios.config";
import lang from "../../utils/LanguagePacks/en-us.json";

export default async function UserTaxDetails(year: number) {
  try {
    const { data } = await axiosInstance.get(`/get_user_tax_details/${year}`);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.response.data.Error || lang.history_not_found);
    } else {
      throw new Error(lang.fetching_error_message);
    }
  }
}
