import React from "react";
import { PricingTableProps } from "../../../interfaces/PricingTableProps.interface";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { BiEdit } from "react-icons/bi";

const PriceTable: React.FC<PricingTableProps> = ({ prices, onUpdate }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-300 text-center">
        <thead>
          <tr className="bg-gray-500">
            <th className="py-2 px-4 border-b font-semibold text-white">
              {" "}
              {/* Center aligned header */}
              {lang.name}
            </th>
            <th className="py-2 px-4 border-b font-semibold text-white">{lang.price}</th>
            <th className="py-2 px-4 border-b font-semibold text-white">{lang.actions}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(prices ?? {}).map(([formName, price]) => (
            <tr className="border-b border-gray-200 hover:bg-gray-400">
              <td className="py-2 px-4 text-center">{formName}</td>
              <td className="py-2 px-4 text-center">${price}</td>
              <td className="py-2 px-4 text-center">
                <button onClick={() => onUpdate(formName)}>
                  <BiEdit className="text-red-500 hover:text-red-600 h-6 w-6" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceTable;
