interface EasyStepCardProps {
  value: string;
  image: string;
}
function EasyStepCard({ value, image }: EasyStepCardProps) {
  return (
    <div className="bg-white-color relative border-[#e0e9e0] border rounded-3xl mb-4 px-20 py-3 cursor-pointer shadow-md hover:shadow-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
      <div className="flex flex-col items-center z-10 relative">
        
        <div className="h-40 w-40 border-[#f4e1cc] border rounded-full shadow-md mb-2 flex items-center justify-center">
          <img src={image} className="h-24 w-24" alt="Signup Elipse" />
        </div>
        <h3 className="font-medium text-student-tax-primary-theme-color text-3xl font-sans ">
          {value}
        </h3>
      </div>
    </div>
  );
}

export default EasyStepCard;
