import React from "react";
import { useNavigate } from "react-router-dom";
import { PROFILE_SETTINGS } from "../../utils/routes";

export default function ProfileSettingsButton() {
  const navigate = useNavigate();

  const navigateToProfileSettings = () => {
    navigate(PROFILE_SETTINGS);
  };

  return (
    <div className="px-6 py-4">
      <button
        onClick={navigateToProfileSettings}
        className="w-[100%] text-center py-2 border border-taxeve-primary-blue bg-taxeve-primary-blue rounded-lg hover:bg-[#222831]"
      >
        Settings
      </button>
    </div>
  );
}
