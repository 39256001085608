import React, { useEffect, useState } from "react";
import { BiUserPin, BiInfoCircle, BiMoney, BiCalendarEdit, BiMapPin } from "react-icons/bi";
import HomeInputComponent from "./HomeInputComponent";
import YesNoRadioButton from "./YesNoRadioButton";
import lang from "../utils/LanguagePacks/en-us.json";
import Modal from "./Modal";
import Dropdown from "./Dropdown";
import State from "../utils/StateList";
// import Checkbox from "../Checkbox";
import Country from "../utils/CountryList";
import YearDropdown from "./YearDropdown";
import { validateForm } from "../classes/HomeFormValidation";
import CustomDatePicker from "./CustomDatePicker";
import Handler from "../classes/Handler";

import FormData from "../interfaces/FormData.interface";
import { ITIN, NA, NON_RESIDENT, RESIDENT, SSN, US } from "../utils/constants";
import { devLogger } from "../utils/logger.config";

import Checkbox from "./Checkbox";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkInternetConnection } from "../classes/CheckNetworkConnection";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "./Decorations/Loading";
import { logEvent } from "../googleAnalytics/ga";
import { actions, category } from "../utils/CustomAnalytics";
import { deleteDocumentsByArray } from "../utils/api/DeleteDocument";
import formatStateName from "../utils/FormatStateName";
import formatCountryName from "../utils/FormatCountryName";
import scrollToTop from "../utils/ScrollTop";
import resetFormData from "../utils/ResetFormData";
import resetFileUpload from "../utils/ResetFileUploadsIds";
import DocumentUploadComponent from "./NewDocumentUpload";

function HomeForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryYear = searchParams.get("year");
  const queryMode = searchParams.get("mode");

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    middleName: "",
    lastName: "",
    zip: "",
    city: "",
    occupation: "",
    addressLine1: "",
    addressLine2: "",
    dob: "",
    tin: "",
    taxResidency: "",
    tintype: "",
    itinApplication: "",
    digitalAssets: "",
    foreign_country_account: "",
    w2Form: null,
    form1099: null,
    anyOtherIncomeForm: null,
    tuitionStatement: null,
    passport: null,
    usEntryExitDates: null,
    allIncomeForm: null,
    visa: null,
    ssn: null,
    state: {
      state_name: "",
      state_id: "",
    },
    country: {
      country_name: "",
      country_id: "",
    },
    foreignState: "",
    additionalInfo: "",
  });

  //modal
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  // for information
  const [isModalOpen, setIsModalOpen] = useState(false);

  // for error
  const [isModalOpenError, setIsModalOpenError] = useState(false);

  //Form Submit Response
  const [formSubmit, setFormSubmit] = useState<string>("");

  //Cacel button warning during  edit
  const [cancelWarning, setCancelWarning] = useState(false);

  //  Checkbox variable :- Foreign Address
  const [isForeignAddress, setIsForeignAddress] = useState<boolean>(false);
  // current year
  const currentYear = new Date().getFullYear();
  // State to hold the selected year
  const [selectedYear, setSelectedYear] = useState(queryYear ? +queryYear : currentYear - 1);

  // For Storing Validation error
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  //For store value of form submit and paid information
  const [detailsSubmit, setDetailsSubmit] = useState(false);
  const [paid, setPaid] = useState(false);

  const [HomeLoading, setHomeLoading] = useState(false);

  //desabled form
  const [formDisabled, setFormDisabled] = useState(false);

  //desable year
  const [yearDisabled, setyearDisabled] = useState(true);

  const [exitingDocument, setexitingDocument] = useState<
    Array<{ _id: string; file_type: string; filename_key: string }>
  >([]);
  //Check user submit the form or not
  useEffect(() => {
    const GetHome = async () => {
      setHomeLoading(true);
      if (queryMode) {
        try {
          if (queryMode === "preview") {
            setFormDisabled(true);
          }

          const response = await Handler.getUserTaxDetails(selectedYear);

          setFormData((prev) => {
            const updatedFormData = {
              ...prev,
              firstName: response?.data?.first_name,
              middleName: response?.data?.middle_name,
              lastName: response?.data?.last_name,
              zip: response?.data?.mailing_add_zip,
              city: response?.data?.mailing_add_city,
              occupation: response?.data?.occupation,
              addressLine1: response?.data?.mailing_add_line1,
              addressLine2: response?.data?.mailing_add_line2,
              dob: formatDateToMmDdYy(response?.data?.date_of_birth),
              tin: response?.data?.tin,
              taxResidency:
                response?.data?.tax_residency === "resident"
                  ? lang.residential
                  : lang.non_residential,
              tintype: response?.data?.tin_type === NA ? lang.nota : response?.data?.tin_type,
              itinApplication: response?.data?.itin_application ? "Yes" : "No",
              digitalAssets: response?.data?.digital_asset ? "Yes" : "No",
              foreign_country_account: response?.data?.foreign_country_account ? "Yes" : "No",
              ...(response?.data?.mailing_add_foreign
                ? {
                    foreignState: response?.data?.mailing_add_state,
                  }
                : {
                    state: {
                      state_name: formatStateName(response?.data?.mailing_add_state, State), // Alabama (AL)
                      state_id: response?.data?.mailing_add_state, // AL
                    },
                  }),
              country: {
                country_id: response?.data?.mailing_add_country,
                country_name: formatCountryName(response?.data?.mailing_add_country, Country),
              },
              additionalInfo: response?.data?.additional_info,
            };
            setexitingDocument(response?.data?.file_ids);
            // Iterate over file_ids to dynamically update form data
            response?.data?.file_ids?.forEach((file: any) => {
              const { _id } = file;
              addDocumentId(_id);
            });

            return updatedFormData;
          });

          setIsForeignAddress(response?.data?.mailing_add_foreign);
          setSelectedYear(response?.data?.taxyear);
        } catch (error: any) {
          devLogger.error(error);
        } finally {
          setHomeLoading(false);
        }
      } else {
        setHomeLoading(true);
        setFormDisabled(false);
        setFormData(resetFormData(formData));
        resetFileUpload(documentIds, setDocumentIds);
        setIsForeignAddress(false);
        setyearDisabled(false);
        setexitingDocument([]);
        try {
          const Data = await Handler.handleUserHome(selectedYear);
          if (Data) {
            if (Data?.data?.taxDetailsSubmitted && Data?.data?.paid) {
              setDetailsSubmit(true);
              setPaid(true);
            } else if (Data?.data?.taxDetailsSubmitted && !Data?.data?.paid) {
              setDetailsSubmit(true);
              setPaid(false);
            } else {
              setDetailsSubmit(false);
              setPaid(false);
            }
          }
        } catch (error: any) {
          devLogger.error(error);
        } finally {
          setHomeLoading(false);
        }
      }
    };

    GetHome();
  }, [selectedYear, queryMode]);

  const addDocumentId = (id: string) => {
    setDocumentIds((prevIds) => [...prevIds, id]);
  };

  const removeDocumentId = (id: string) => {
    setDocumentIds((prevIds) => prevIds.filter((docId) => docId !== id));
  };
  const handleYearChange = (year: number) => {
    setSelectedYear(year); // Update state with the selected year
  };

  // For information

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // For Api Error
  const handleOpenModalError = () => setIsModalOpenError(true);
  const handleCloseModalError = () => setIsModalOpenError(false);

  // For cancel warning
  const handleOpenModalCancelWarning = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCancelWarning(true);
  };
  const handleCloseModalCancelWarning = () => {
    setCancelWarning(false);
  };

  //Additional Question for Residentials

  // => For Residentaial
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFormData((prevData) => ({
      ...prevData, // keep the other data intact
      taxResidency: value, // update the selectedOption field in the object
      digitalAssets: "",
    }));

    if (documentIds.length > 0) {
      try {
        await deleteDocumentsByArray(documentIds);
      } catch (error) {
        devLogger.error(error);
      }
    }
  };

  // =>For Id
  const handleChangeID = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      tintype: value,
      tin: "",
      itinApplication: "",
    }));
  };
  // =>Do you want ITIN
  const handleChangeITIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      itinApplication: value,
    }));
  };
  const handleFormData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === "tin") {
      const numericValue = value.replace(/\D/g, "");
      let formattedValue = numericValue;
      if (numericValue.length > 3 && numericValue.length <= 5) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
      } else if (numericValue.length > 5) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          5
        )}-${numericValue.slice(5, 9)}`;
      }

      // Update the formData with the formatted value for the input field
      setFormData((prev) => ({
        ...prev,
        tin: formattedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelection = (selectedOption: { name: string; code: string }) => {
    setFormData((prev) => ({
      ...prev,
      state: {
        state_name: selectedOption.name,
        state_id: selectedOption.code,
      },
    }));
  };
  const handleSelectionCountry = (selectedOption: { name: string; code: string }) => {
    setFormData((prev) => ({
      ...prev,
      country: {
        country_name: selectedOption.name,
        country_id: selectedOption.code,
      },
    }));
  };

  const formatDateToYyyyMmDd = (dateString: string): string => {
    // Split the input date string (mm-dd-yyyy)
    const [month, day, year] = dateString.split("-");

    // Return the formatted date string (yyyy-mm-dd)
    return `${year}${month}${day}`;
  };
  const formatDateToMmDdYy = (dateString: string): string => {
    // Extract year, month, and day from the string
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    // Return the formatted date
    return `${month}-${day}-${year}`;
  };

  const removeHyphens = (input: string): string => {
    return input.replace(/-/g, ""); // Remove all hyphens
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isOnline = await checkInternetConnection();

    if (!isOnline) {
      toast.error(lang.no_internet_errror);
      return; // Stop the submission if offline
    }
    const validationErrors = validateForm(formData, isForeignAddress);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Construct the object to send as key-value pairs
      // const file_ids = Object.entries(fileUpload)
      //   .filter(([key, value]) => value !== "") // Keep only entries with non-empty values
      //   .map(([key, value]) => value);

      const dataToSend = {
        taxyear: selectedYear.toString(),
        first_name: formData.firstName,
        middle_name: formData.middleName,
        last_name: formData.lastName,
        occupation: formData.occupation,
        date_of_birth: formatDateToYyyyMmDd(formData.dob),
        mailing_add_foreign: isForeignAddress,
        mailing_add_line1: formData.addressLine1,
        mailing_add_line2: formData.addressLine2,
        mailing_add_city: formData.city,
        mailing_add_state: !isForeignAddress ? formData.state.state_id : formData.foreignState,
        mailing_add_zip: formData.zip,
        mailing_add_country: formData.country.country_id ? formData.country.country_id : US,
        tax_residency: formData.taxResidency === lang.residential ? RESIDENT : NON_RESIDENT,
        tin_type: formData.tintype === lang.nota ? NA : formData.tintype,
        tin: removeHyphens(formData.tin),
        itin_application: formData.itinApplication === "Yes" ? true : false,
        digital_asset: formData.digitalAssets === "Yes" ? true : false,
        foreign_country_account: formData.foreign_country_account === "Yes" ? true : false,
        additional_info: formData.additionalInfo,
        file_ids: documentIds,
      };

      try {
        // Call the handler with the constructed object
        const res = await Handler.handleFormUpload(dataToSend);
        if (res?.message) {
          logEvent(category.form_submit, actions.tax_details_success);
          // Navigate to `/cart` with the year as a query parameter
          navigate(`/cart?year=${selectedYear}`);
        }
      } catch (error: unknown) {
        let errorMessage = lang.unexpected_error;

        // Check if error is an object
        if (typeof error === "object" && error !== null) {
          if ("response" in error && typeof (error as any).response.data === "object") {
            const errorData = (error as any).response.data;

            // Check if the expected error message structure is present
            if (typeof errorData.Error === "string") {
              errorMessage = errorData.Error; // Set the extracted message
            } else {
              // Fallback for other possible structures
              errorMessage = errorData.message || errorMessage;
            }
          }
        }

        setFormSubmit(errorMessage); // Set the extracted message
        logEvent(category.form_submit, actions.tax_details_failed, errorMessage);

        handleOpenModalError();
      }
    } else {
      return;
    }
  };
  const handleEdit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFormDisabled(false);
    scrollToTop();
  };
  const updateTaxDetails = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const validationErrors = validateForm(formData, isForeignAddress);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        const updatedData = {
          taxyear: selectedYear.toString(),
          first_name: formData.firstName,
          middle_name: formData.middleName,
          last_name: formData.lastName,
          occupation: formData.occupation,
          date_of_birth: formatDateToYyyyMmDd(formData.dob),
          mailing_add_foreign: isForeignAddress,
          mailing_add_line1: formData.addressLine1,
          mailing_add_line2: formData.addressLine2,
          mailing_add_city: formData.city,
          mailing_add_state: !isForeignAddress ? formData.state.state_id : formData.foreignState,
          mailing_add_zip: formData.zip,
          mailing_add_country: formData.country.country_id ? formData.country.country_id : US,
          tax_residency: formData.taxResidency === lang.residential ? RESIDENT : NON_RESIDENT,
          tin_type: formData.tintype === lang.nota ? NA : formData.tintype,
          tin: removeHyphens(formData.tin),
          itin_application: formData.itinApplication === "Yes" ? true : false,
          digital_asset: formData.digitalAssets === "Yes" ? true : false,
          foreign_country_account: formData.foreign_country_account === "Yes" ? true : false,
          additional_info: formData.additionalInfo,
          file_ids: documentIds,
        };
        const res = await Handler.handleUpdateUserTaxDetails(updatedData);
        if (res) {
          logEvent(category.form_update, actions.tax_details_update_success);
          navigate(`/cart?year=${selectedYear}`);
        }
      }
    } catch (error) {
      devLogger.error(error);
      let errorMessage = lang.unexpected_error;

      // Check if error is an object
      if (typeof error === "object" && error !== null) {
        if ("response" in error && typeof (error as any).response.data === "object") {
          const errorData = (error as any).response.data;

          // Check if the expected error message structure is present
          if (typeof errorData.Error === "string") {
            errorMessage = errorData.Error; // Set the extracted message
          } else {
            // Fallback for other possible structures
            errorMessage = errorData.message || errorMessage;
          }
        }
      }

      setFormSubmit(errorMessage); // Set the extracted message
      logEvent(category.form_update, actions.tax_details_update_failed, errorMessage);
      handleOpenModalError();
    }
  };
  const handleDiscardChanges = () => {
    navigate(`/cart?year=${selectedYear}`);
    handleCloseModalCancelWarning();
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] mb-2 max-w-7xl mx-auto p-6  border-gray-300 md:border-2"
    >
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl font-semibold ">Tax Form</h2>
      </div>
      <div className=" flex-col md:flex-row mb-4 flex gap-3 md:items-center">
        <div className="flex gap-1">
          <span>{lang.tax_year}</span>
          <span className="text-red-700 text-2xl">*</span>
        </div>

        <YearDropdown
          currentYear={currentYear}
          onYearChange={handleYearChange}
          selectedYear={selectedYear}
          disbled={yearDisabled}
        />
      </div>

      {HomeLoading ? (
        <span>
          <Loading />
        </span>
      ) : detailsSubmit && paid ? (
        <div className="alert alert-warning mt-4 flex items-center flex-col">
          <p className="font-bold text-green-700 text-xl text-center">{lang.paidmessage}</p>
          <button
            className="btn btn-primary mt-2 py-2 px-4"
            onClick={() => navigate(`/history?year=${selectedYear}`)} // Assuming you are using react-router-dom for navigation
          >
            History
          </button>
        </div>
      ) : detailsSubmit && !paid ? (
        <div className="alert alert-warning mt-4 flex items-center flex-col">
          <p className="font-bold text-red-700 text-xl text-center">{lang.unPaidWarning}</p>
          <button
            className="btn btn-primary mt-2 py-2 px-4"
            onClick={() => navigate(`/cart?year=${selectedYear}`)} // Assuming you are using react-router-dom for navigation
          >
            Go to Cart
          </button>
        </div>
      ) : (
        <div>
          <div className="flex md:flex-row justify-between flex-col ">
            <div className="flex flex-col mb-4">
              <HomeInputComponent
                label={lang.first_name}
                placeholder={lang.first_name_placeholder}
                Icon={BiUserPin}
                required={true}
                value={formData.firstName}
                name="firstName"
                onChange={handleFormData}
                disabled={formDisabled}
              />
              {!formData.firstName && errors?.firstName && (
                <span className="text-red-700">{errors?.firstName}</span>
              )}
            </div>
            <div className="flex flex-col mb-4">
              <HomeInputComponent
                label={lang.middle_name}
                placeholder={lang.middle_name_placeholder}
                Icon={BiUserPin}
                required={false}
                value={formData.middleName}
                name="middleName"
                onChange={handleFormData}
                disabled={formDisabled}
              />
            </div>

            <div className="flex flex-col mb-4">
              <HomeInputComponent
                label={lang.last_name}
                placeholder={lang.last_name_placeholder}
                Icon={BiUserPin}
                required={true}
                value={formData.lastName}
                name="lastName"
                onChange={handleFormData}
                disabled={formDisabled}
              />
              {!formData.lastName && errors?.lastName && (
                <span className="text-red-700">{errors?.lastName}</span>
              )}
            </div>
          </div>
          <div className="flex md:flex-row justify-between flex-col md:w-[60.5%]">
            <div className="flex flex-col mb-4">
              <HomeInputComponent
                label={lang.occupation}
                placeholder={lang.occupation_placeholder}
                Icon={BiMoney}
                required={true}
                value={formData.occupation}
                name="occupation"
                onChange={handleFormData}
                disabled={formDisabled}
              />
              {!formData.occupation && errors?.occupation && (
                <span className="text-red-700">{errors?.occupation}</span>
              )}
            </div>
            <div className="flex flex-col mb-4">
              <CustomDatePicker
                value={formData.dob}
                onChange={(newDate) =>
                  handleFormData({
                    target: { name: "dob", value: newDate },
                  } as React.ChangeEvent<HTMLInputElement>)
                } // Update dob in formData
                startYear={1950}
                endYear={currentYear} // Pass the range for year selection
                label={lang.dob}
                placeholder={lang.dob_placeholder}
                required={true}
                Icon={BiCalendarEdit}
                disabled={formDisabled}
              />
              {!formData.dob && errors?.dob && <span className="text-red-700">{errors?.dob}</span>}
            </div>
          </div>
          {/* Address Section  */}
          <div>
            <div className="flex flex-col md:flex-row gap-4  mb-4">
              <h2 className="text-xl font-semibold ">{lang.address}</h2>

              <Checkbox
                label={lang.foreign_address}
                checked={isForeignAddress}
                onChange={() => {
                  setIsForeignAddress(!isForeignAddress);
                  setFormData((prev) => ({
                    ...prev,
                    country: {
                      country_name: "",
                      country_id: "",
                    },
                    state: {
                      state_name: "",
                      state_id: "",
                    },
                    foreignState: "",
                  }));
                }}
                disabled={formDisabled}
              />
            </div>
            <div className="flex md:flex-row justify-between flex-col">
              <div className="flex flex-col mb-4">
                <HomeInputComponent
                  label={lang.address1}
                  placeholder={lang.address_placeholder}
                  Icon={BiMapPin}
                  required={true}
                  value={formData.addressLine1}
                  name="addressLine1"
                  onChange={handleFormData}
                  disabled={formDisabled}
                />
                {!formData.addressLine1 && errors?.addressLine1 && (
                  <span className="text-red-700">{errors?.addressLine1}</span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <HomeInputComponent
                  label={lang.address2}
                  placeholder={lang.address_placeholder}
                  Icon={BiMapPin}
                  required={false}
                  value={formData.addressLine2}
                  name="addressLine2"
                  onChange={handleFormData}
                  disabled={formDisabled}
                />
              </div>
              <div className="flex flex-col mb-4">
                <HomeInputComponent
                  label={isForeignAddress ? lang.foreign_city : lang.city}
                  placeholder={lang.city_placeholder}
                  Icon={BiMapPin}
                  required={true}
                  value={formData.city}
                  name="city"
                  onChange={handleFormData}
                  disabled={formDisabled}
                />
                {!formData.city && errors?.city && (
                  <span className="text-red-700">{errors?.city}</span>
                )}
              </div>
            </div>
            <div
              className={`flex md:flex-row justify-between flex-col ${
                !isForeignAddress && "md:w-[60.5%] "
              } `}
            >
              {isForeignAddress ? (
                <div className="flex flex-col mb-4">
                  <HomeInputComponent
                    label={lang.foreign_state}
                    placeholder={lang.state_placeholder}
                    Icon={BiMapPin}
                    required={false}
                    value={formData.foreignState}
                    name="foreignState"
                    onChange={handleFormData}
                    disabled={formDisabled}
                  />
                  {!formData.foreignState && errors?.foreignState && (
                    <span className="text-red-700">{errors?.foreignState}</span>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex flex-col mb-4">
                    <Dropdown
                      options={State}
                      label={lang.state}
                      onChange={handleSelection}
                      disabled={formDisabled}
                      value={formData?.state?.state_name}
                    />
                    {!formData?.state?.state_name && errors?.state && (
                      <span className="text-red-700">{errors?.state}</span>
                    )}
                  </div>
                </>
              )}
              {isForeignAddress && (
                <div className="flex flex-col mb-4">
                  <Dropdown
                    options={Country}
                    label={lang.country}
                    onChange={handleSelectionCountry}
                    disabled={formDisabled}
                    value={formData?.country?.country_name}
                  />
                  {!formData?.country?.country_name && errors?.country && (
                    <span className="text-red-700">{errors?.country}</span>
                  )}
                </div>
              )}
              <div className="flex flex-col mb-4">
                <HomeInputComponent
                  label={isForeignAddress ? lang.postal : lang.zip}
                  placeholder={isForeignAddress ? lang.postal_placeholder : lang.zip_placeholder}
                  Icon={BiMapPin}
                  required={true}
                  value={formData.zip}
                  name="zip"
                  onChange={handleFormData}
                  disabled={formDisabled}
                />
                {!formData.zip && errors?.zip && (
                  <span className="text-red-700">{errors?.zip}</span>
                )}
              </div>
            </div>
          </div>

          {/* Residential and non residential code  */}
          <div className="flex flex-col  md:flex-row gap-4">
            <div className="mb-4 w-full md:w-1/2   border-gray-300 border-2 p-2.5 rounded-lg  ">
              <div className=" md:flex-row flex items-center justify-between xs:flex-col ">
                <label className="flex flex-row gap-2 text-sm font-medium  items-center">
                  <div className="flex flex-row items-center gap-1">
                    <span>{lang.are_you_a_tax}</span>
                    <span className="text-red-700 text-2xl">*</span>
                  </div>

                  <BiInfoCircle className="h-6 w-6" onClick={handleOpenModal} />
                </label>
                <div className="flex items-center xs:justify-between xs:w-full md:w-auto xs:flex-row ">
                  <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                    <input
                      type="radio"
                      value={lang.residential}
                      checked={formData.taxResidency === lang.residential}
                      onChange={handleChange}
                      className="mr-2"
                      disabled={formDisabled}
                    />
                    {lang.residential}
                  </label>
                  <label className={`${formDisabled && "text-slate-400"}`}>
                    <input
                      type="radio"
                      value={lang.non_residential}
                      checked={formData.taxResidency === lang.non_residential}
                      onChange={handleChange}
                      className="mr-2"
                      disabled={formDisabled}
                    />
                    {lang.non_residential}
                  </label>
                </div>
              </div>
              {!formData.taxResidency && (
                <div className=" flex flex-row gap-2 items-center mt-2  xs:w-full xs:justify-center">
                  {errors?.taxResidency && (
                    <span className="text-red-700">{errors?.taxResidency}</span>
                  )}
                </div>
              )}
            </div>

            <div className="mb-4 w-full md:w-1/2  flex flex-col justify-center  border-gray-300 border-2 p-2.5 rounded-lg ">
              <div className="md:flex-row flex items-center justify-between xs:flex-col">
                <label className=" text-sm font-medium ">
                  {lang.id_number}
                  <span className="text-red-700 text-2xl">*</span>
                </label>
                <div className="flex items-center ">
                  <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                    <input
                      type="radio"
                      value={lang.ssn}
                      checked={formData.tintype === lang.ssn}
                      onChange={handleChangeID}
                      className="mr-2"
                      disabled={formDisabled}
                    />
                    {lang.ssn}
                  </label>
                  <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                    <input
                      type="radio"
                      value={lang.itin}
                      checked={formData.tintype === lang.itin}
                      onChange={handleChangeID}
                      className="mr-2"
                      disabled={formDisabled}
                    />
                    {lang.itin}
                  </label>
                  <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                    <input
                      type="radio"
                      value={lang.nota}
                      checked={formData.tintype === lang.nota}
                      onChange={handleChangeID}
                      className="mr-2 ml-2"
                      disabled={formDisabled}
                    />
                    {lang.nota}
                  </label>
                </div>
              </div>
              {formData.tintype && formData.tintype === "None of the above" && (
                <>
                  <div className=" flex flex-row gap-2 items-center mt-2">
                    <BiInfoCircle className="h-6 w-6" />
                    <div className="flex justify-between">
                      {/* <p className=" text-xs   font-semibold flex flex-row w-1/2">
                    {lang.itin_question} <span className="text-red-700 text-2xl">*</span>
                  </p> */}
                      <label className=" text-sm font-medium w-1/2 ">
                        {lang.itin_question}
                        <span className="text-red-700 text-2xl">*</span>
                      </label>
                      <div className="flex items-center ">
                        <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                          <input
                            type="radio"
                            value={lang.yes}
                            checked={formData.itinApplication === lang.yes}
                            onChange={handleChangeITIN}
                            className="mr-2"
                            disabled={formDisabled}
                          />
                          {lang.yes}
                        </label>
                        <label className={` mr-4 ${formDisabled && "text-slate-400"}`}>
                          <input
                            type="radio"
                            value={lang.no}
                            checked={formData.itinApplication === lang.no}
                            onChange={handleChangeITIN}
                            className="mr-2"
                            disabled={formDisabled}
                          />
                          {lang.no}
                        </label>
                      </div>
                    </div>
                  </div>
                  {errors?.itinApplication && (
                    <span className="text-red-700">{errors?.itinApplication}</span>
                  )}
                </>
              )}
              {!formData.tintype && (
                <div className=" flex flex-row gap-2 items-center mt-2  xs:w-full xs:justify-center">
                  {errors?.tin && <span className="text-red-700">{errors?.tintype}</span>}
                </div>
              )}
              {formData.tintype && (formData.tintype === ITIN || formData.tintype === SSN) && (
                <div className=" flex  gap-2 items-center mt-2 justify-between flex-col md:flex-row">
                  <label className="block text-sm font-medium ">
                    {" "}
                    Enter the {formData.tintype} Number{" "}
                    <span className="text-red-700 text-xl">*</span>
                  </label>
                  <div>
                    <div
                      className={`border border-gray-300 rounded-md shadow-sm focus-within:ring focus-within:ring-blue-300 ${
                        formDisabled && "bg-slate-200"
                      }`}
                    >
                      <input
                        type="text"
                        value={formData.tin}
                        name="tin"
                        onChange={handleFormData}
                        className="border-0 focus:border-transparent focus:outline-none focus:ring-0 "
                        disabled={formDisabled}
                      />
                    </div>

                    <div className=" flex flex-row gap-2 items-center mt-2  xs:w-full xs:justify-center">
                      {!formData.tin && errors?.tin && (
                        <span className="text-red-700">{errors?.tin}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Additional question for residential  */}
          {formData?.taxResidency && formData?.taxResidency === lang.residential && (
            <div className="flex flex-col  md:flex-row gap-4">
              <div className="mb-4 w-full md:w-1/2  border-gray-300 border-2 p-2.5 rounded-lg ">
                <YesNoRadioButton
                  options={[lang.yes, lang.no]}
                  selectedOption={formData.digitalAssets}
                  name="digitalAssets"
                  onChange={handleFormData}
                  lebel={`${lang.digital_asset_part1} ${selectedYear} ${lang.digital_asset_part2}`}
                  required={true}
                  disabled={formDisabled}
                />
                {!formData.digitalAssets && errors?.digitalAssets && (
                  <span className="text-red-700">{errors?.digitalAssets}</span>
                )}
              </div>
              <div className="mb-4 w-full md:w-1/2  border-gray-300 border-2 p-2.5 rounded-lg">
                <YesNoRadioButton
                  options={[lang.yes, lang.no]}
                  selectedOption={formData.foreign_country_account}
                  name="foreign_country_account"
                  onChange={handleFormData}
                  lebel={`${lang.foreign_country_part1} ${selectedYear} ${lang.foreign_country_part2}`}
                  required={true}
                  disabled={formDisabled}
                />

                {!formData.foreign_country_account && errors?.foreign_country_account && (
                  <span className="text-red-700">{errors?.foreign_country_account}</span>
                )}
              </div>
            </div>
          )}
          {/* Additional question for non residential  */}
          {formData?.taxResidency && formData?.taxResidency === lang.non_residential && (
            <div className="flex flex-col  md:flex-row gap-4">
              <div className="mb-4 w-full md:w-1/2  border-gray-300 border-2 p-2.5 rounded-lg ">
                <YesNoRadioButton
                  options={["yes", "no"]}
                  selectedOption={formData.digitalAssets}
                  name="digitalAssets"
                  onChange={handleFormData}
                  lebel={`${lang.digital_asset_part1} ${selectedYear} ${lang.digital_asset_part2}`}
                  required={true}
                  disabled={formDisabled}
                />
                {!formData.digitalAssets && errors?.digitalAssets && (
                  <span className="text-red-700">{errors?.digitalAssets}</span>
                )}
              </div>
            </div>
          )}
          <div className="mb-4">
            <label className="block text-sm font-medium " htmlFor="message">
              {lang.additional_information}
            </label>
            <textarea
              name="additionalInfo"
              className={`${
                formDisabled && "bg-slate-200"
              } mt-1 block w-full border-gray-300 rounded-md focus:outline-none focus:ring-0 shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]`}
              placeholder={lang.additional_information_placeholder}
              required={false}
              value={formData.additionalInfo}
              onChange={handleFormData}
              disabled={formDisabled}
            ></textarea>
          </div>
          
          {/* New Document Upload  */}
          <DocumentUploadComponent
            taxyear={selectedYear}
            documentIds={documentIds}
            addDocumentId={addDocumentId}
            removeDocumentId={removeDocumentId}
            disabled={formDisabled}
            existingFiles={exitingDocument}
          />

          

          {/* modal  */}
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            title={lang.informaton}
            content={lang.residential_information}
            buttonText={lang.understand}
            type="info"
          />
          <Modal
            isOpen={isModalOpenError}
            onClose={handleCloseModalError}
            title={lang.error}
            content={formSubmit}
            buttonText={lang.fix_it}
            type="error"
          />

          <Modal
            isOpen={cancelWarning}
            onClose={handleCloseModalCancelWarning}
            title={lang.warning}
            content={lang.cancel_warning}
            type="info"
            buttonText={lang.ok}
            isCancel={true}
            onAgree={handleDiscardChanges}
          />

          <ToastContainer />
          <div className=" flex justify-center">
            {queryMode && formDisabled ? (
              <div className="flex gap-10">
                <button
                  onClick={handleEdit}
                  className="w-64 bg-taxeve-secondary-blue text-white font-semibold py-2 rounded-md hover:bg-taxeve-primary-blue transition"
                >
                  Edit
                </button>
                <button
                  onClick={() => navigate(`/cart?year=${selectedYear}`)}
                  className="w-64 bg-taxeve-secondary-blue text-white font-semibold py-2 rounded-md hover:bg-taxeve-primary-blue transition"
                >
                  Continue
                </button>
              </div>
            ) : queryMode && !formDisabled ? (
              <div className="flex gap-10">
                <button
                  onClick={handleOpenModalCancelWarning}
                  className="w-64 bg-taxeve-secondary-blue text-white font-semibold py-2 rounded-md hover:bg-taxeve-primary-blue transition"
                >
                  Cancel
                </button>
                <button
                  onClick={updateTaxDetails}
                  className="w-64 bg-taxeve-secondary-blue text-white font-semibold py-2 rounded-md hover:bg-taxeve-primary-blue transition"
                >
                  Update
                </button>
              </div>
            ) : (
              <button
                type="submit"
                className="w-64 bg-taxeve-secondary-blue text-white font-semibold py-2 rounded-md hover:bg-taxeve-primary-blue transition"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
}

export default HomeForm;
