import React from "react";
import lang from "../utils/LanguagePacks/en-us.json";
import { FaAppStore, FaFacebook, FaTwitter } from "react-icons/fa";
import { LuLinkedin } from "react-icons/lu";
import { MdMail } from "react-icons/md";
import { BiGlobe, BiPhone } from "react-icons/bi";
import { BsAndroid } from "react-icons/bs";
function Footer() {
  return (
    <>
      <div className="relative py-20 px-52 bg-student-tax-primary-theme-color">
        <div className="flex flex-row justify-between">
          <div className=" w-1/5">
            <span className="font-semibold font-mono text-white-color text-3xl">
              {lang.web_name}
            </span>
            <hr className="border-t-2 border-white-color mb-4" />
            <span className="text-white-color text-sm text-center font-sans font-medium ">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s
            </span>
            <div className="mt-2 flex flex-row items-center gap-4">
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <FaFacebook className=" text-student-tax-secondary-theme-color" />
              </div>
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <LuLinkedin className=" text-student-tax-secondary-theme-color" />
              </div>
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <FaTwitter className=" text-student-tax-secondary-theme-color" />
              </div>
            </div>
          </div>
          <div className=" w-1/5">
            <span className="font-semibold font-mono text-white-color text-3xl">
              {lang.useful_link}
            </span>
            <hr className="border-t-2 border-white-color mb-4" />
            <div className="flex flex-col">
              <span className="text-white-color text-sm hover:underline cursor-pointer  font-sans font-medium">
                Home
              </span>
              <span className="text-white-color text-sm hover:underline cursor-pointer  font-sans font-medium">
                About Us
              </span>
              <span className="text-white-color text-sm hover:underline cursor-pointer  font-sans font-medium">
                History
              </span>
              <span className="text-white-color text-sm hover:underline cursor-pointer  font-sans font-medium">
                Contact us
              </span>
            </div>
          </div>
          <div className=" w-1/5">
            <span className="font-semibold font-mono text-white-color text-3xl">
              {lang.contact_us}
            </span>
            <hr className="border-t-2 border-white-color mb-4" />
            <div className="gap-2 flex flex-col">
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <MdMail className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  anc@gmail.com
                </span>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BiPhone className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  5547875454454
                </span>
              </div>
            </div>
          </div>
          <div className=" w-1/5">
            <span className="font-semibold font-mono text-white-color text-3xl">
              {lang.our_products}
            </span>
            <hr className="border-t-2 border-white-color mb-4" />
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BiGlobe className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  TaxEve
                </span>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BsAndroid className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {lang.web_name}
                </span>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <FaAppStore className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {lang.web_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 px-52 bg-dark-green">
        <div className="flex flex-row items-center justify-between">
          <span className="font-medium text-xs text-white-color">
            © 2024 StudentTax All rights reserved
          </span>
          <div className="flex flex-row gap-1">
            <span className="font-medium text-xs text-white-color hover:underline cursor-pointer hover:text-blue-700">Privacy Policy</span> <span className="font-medium text-xs text-white-color">|</span>
            <span className="font-medium text-xs text-white-color hover:underline cursor-pointer hover:text-blue-700">Terms & Conditions</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
